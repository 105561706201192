import IEndOfProductionDateRepository from '../domain/IEndOfProductionDateRepository';
import endOfProductionDateRepository from '../infrastructure/repositories/EndOfProductionDateRepository';
import { endOfProductionDataMapper } from '../domain/endOfProductionDataMapper';

class GetEndOfProductionDateDataService {
  private readonly _endOfProductionDateRepository: IEndOfProductionDateRepository;

  constructor({ repository }) {
    this._endOfProductionDateRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._endOfProductionDateRepository
      .get({ queryParams })
      .then((res) => endOfProductionDataMapper(res));
  }
}

export default new GetEndOfProductionDateDataService({
  repository: endOfProductionDateRepository
});

export { GetEndOfProductionDateDataService };
