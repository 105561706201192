import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import directDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class DownloadDirectDeliveryUseCase {
  private readonly _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ directDeliveryRepository }) {
    this._directDeliveryRepository = directDeliveryRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._directDeliveryRepository.downloadDirectDelivery({
      queryParams
    });
  }
}

export default new DownloadDirectDeliveryUseCase({
  directDeliveryRepository: directDeliveryRepository
});

export { DownloadDirectDeliveryUseCase };
