import IProviderCodesRepository from '../../domain/UploadProviderCodes/IProviderCodes';
import { UploadProviderCodes } from '../../domain/UploadProviderCodes/UploadProviderCodes';
import providerCodesRepository from '../../infraestructure/repositories/ProviderCodesRepository';

class UploadProviderCodesUseCase {
  private readonly _providerCodesRepository: IProviderCodesRepository;

  constructor({ providerCodesRepository }) {
    this._providerCodesRepository = providerCodesRepository;
  }

  execute(file: object) {
    return this._providerCodesRepository
      .uploadProviderCodes(file)
      .then((res) => UploadProviderCodes.generateFromDto(res));
  }
}

export default new UploadProviderCodesUseCase({
  providerCodesRepository: providerCodesRepository
});

export { UploadProviderCodesUseCase };
