import IUsersRepository from '../domain/IUsersRepository';
import UserDashboardsData from '../domain/UserDashboardsData';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class GetUserDashboardsDataService {
  private readonly _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute() {
    return this._usersRepository
      .getUserDashboards()
      .then((res) => UserDashboardsData.generateFromDTO(res));
  }
}

export default new GetUserDashboardsDataService({
  repository: usersRepository
});

export { GetUserDashboardsDataService };
