import {
  API_GET_REFILLS,
  API_GET_REFILLS_ALERTS,
  API_GET_REFILLS_STATUS,
  API_GET_REFILLS_SATURATION
} from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import RefillsDataDTO from '../dto/RefillsDataDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IRefillsRepository from '../../domain/IRefillsRepository';
import RefillsStatusesDTO from '../dto/RefillsStatusDTO';
import RefillsAlertsDTO from '../dto/RefillsAlertsDTO';
import RefillsSaturationDTO from '../dto/RefillsSaturationDTO';

class RefillsRepository extends BaseRepository implements IRefillsRepository {
  private readonly _api: IApi;
  private readonly _apiGetRefillsUrl: string;
  private readonly _apiGetRefillsStatus: string;
  private readonly _apiGetRefillsAlerts: string;
  private readonly _apiGetRefillsSaturation: string;

  constructor({
    api,
    apiGetRefillsUrl,
    apiGetRefillsStatus,
    apiGetRefillsAlerts,
    apiGetRefillsSaturation
  }) {
    super();
    this._api = api;
    this._apiGetRefillsUrl = apiGetRefillsUrl;
    this._apiGetRefillsStatus = apiGetRefillsStatus;
    this._apiGetRefillsAlerts = apiGetRefillsAlerts;
    this._apiGetRefillsSaturation = apiGetRefillsSaturation;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetRefillsUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new RefillsDataDTO(res));
  }

  getRefillsStatus() {
    const url = this._apiGetRefillsStatus;
    return this._api.get(url).then((res) => new RefillsStatusesDTO(res));
  }

  getRefillsAlerts() {
    const url = this._apiGetRefillsAlerts;
    return this._api.get(url).then((res) => new RefillsAlertsDTO(res));
  }
  getRefillsSaturation() {
    const url = this._apiGetRefillsSaturation;
    return this._api.get(url).then((res) => new RefillsSaturationDTO(res));
  }
}

export default new RefillsRepository({
  api: api,
  apiGetRefillsUrl: API_GET_REFILLS,
  apiGetRefillsStatus: API_GET_REFILLS_STATUS,
  apiGetRefillsAlerts: API_GET_REFILLS_ALERTS,
  apiGetRefillsSaturation: API_GET_REFILLS_SATURATION
});

export { RefillsRepository };
