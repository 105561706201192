import {
  API_GET_GEOLOCATION,
  API_GET_GEOLOCATION_FINISHED_GOOD_TIMELINE,
  API_GET_GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS,
  API_GET_SEARCH_FINISHED_GOOD,
  API_GET_GEOLOCATED_TRANSPORTS,
  API_GET_TRANSPORT_DETAILS,
  API_GET_GEOLOCATED_VESSELS
} from './urls';
import api from 'modules/shared/infrastructure/api/Api';
import IApi from 'modules/shared/domain/IApi';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import DeliveriesGeolocationDTO from 'modules/outbound/fulfillment/infrastructure/dto/DeliveriesGeolocationDTO';
import DeliveriesGeolocationCheckpointDTO from 'modules/outbound/fulfillment/infrastructure/dto/DeliveriesGeolocationCheckpointDTO';
import FinishedGoodsListDTO from 'modules/outbound/fulfillment/infrastructure/dto/FinishedGoodsListDTO';
import FinishedGoodDTO from 'modules/outbound/fulfillment/infrastructure/dto/FinishedGoodDTO';
import FinishedGoodCheckpointsTimelineDTO from 'modules/outbound/fulfillment/infrastructure/dto/FinishedGoodCheckpointsTimelineDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import FinishedGoodTransportDetailsDTO from '../dto/FinishedGoodTransportDetailsDTO';
import GeolocatedTransportsResponseDTO from '../dto/GeolocatedTransportsResponseDTO';
import GeolocatedVesselsResponseDTO from '../dto/GeolocatedVesselsResponseDTO';

class DeliveriesGeolocationRepository
  extends BaseRepository
  implements ITransitRepository
{
  private readonly _api: IApi;
  private readonly _apiGetDeliveriesGeolocation: string;
  private readonly _apiGetFinishedGood: string;
  private readonly _apiGetFinishedGoodTimeline: string;
  private readonly _apiGetFinishedGoodTransportDetails: string;
  private readonly _apiGetTransportDetails: string;
  private readonly _apiGetGeolocatedTransports: string;
  private readonly _apiGetGeolocatedVessels: string;

  constructor({
    api,
    apiGetDeliveriesGeolocation,
    apiGetFinishedGood,
    apiGetFinishedGoodTimeline,
    apiGetFinishedGoodTransportDetails,
    apiGetTransportDetails,
    apiGetGeolocatedTransports,
    apiGetGeolocatedVessels
  }) {
    super();
    this._api = api;
    this._apiGetDeliveriesGeolocation = apiGetDeliveriesGeolocation;
    this._apiGetFinishedGood = apiGetFinishedGood;
    this._apiGetFinishedGoodTimeline = apiGetFinishedGoodTimeline;
    this._apiGetFinishedGoodTransportDetails =
      apiGetFinishedGoodTransportDetails;
    this._apiGetTransportDetails = apiGetTransportDetails;
    this._apiGetGeolocatedTransports = apiGetGeolocatedTransports;
    this._apiGetGeolocatedVessels = apiGetGeolocatedVessels;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetDeliveriesGeolocation}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) =>
        res.geolocationDeliveriesByCountry.map(
          (item) => new DeliveriesGeolocationDTO(item)
        )
      )
      .catch((err) => {
        throw new Error(err);
      });
  }

  getCheckpoints({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    const url = `${
      this._apiGetDeliveriesGeolocation
    }/${countryCode}${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => ({
        countryCode: countryCode,
        checkpoints: res.map(
          (item) => new DeliveriesGeolocationCheckpointDTO(item)
        )
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getFinishedGoodsByCheckpoint({ countryCode, checkpointRef, queryParams }) {
    const url = `${
      this._apiGetDeliveriesGeolocation
    }/${countryCode}/${checkpointRef}${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) => ({
        countryCode,
        checkpointRef,
        ...new FinishedGoodsListDTO(res)
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getFinishedGood({ queryParams }) {
    const url = `${this._apiGetFinishedGood}/${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => {
      if (Object.keys(res).length) {
        return new FinishedGoodDTO(res);
      }
      throw new Error('vinError');
    });
  }

  getFinishedGoodCheckpointsTimeline({ vin, queryParams }) {
    const url = `${
      this._apiGetFinishedGoodTimeline
    }/${vin}${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => {
      return new FinishedGoodCheckpointsTimelineDTO(res);
    });
  }

  getFinishedGoodTransportDetails({ vin }) {
    const url = `${this._apiGetFinishedGoodTransportDetails}/${vin}`;
    return this._api.get(url).then((res) => {
      return new FinishedGoodTransportDetailsDTO(res);
    });
  }

  getTransportDetailsById({ transportId }) {
    const url = `${this._apiGetTransportDetails}/${transportId}`;
    return this._api.get(url).then((res) => {
      return new FinishedGoodTransportDetailsDTO(res);
    });
  }

  getGeolocatedTransports() {
    const url = `${this._apiGetGeolocatedTransports}`;
    return this._api
      .get(url)
      .then((res) => new GeolocatedTransportsResponseDTO(res));
  }

  getGeolocatedVessels() {
    const url = `${this._apiGetGeolocatedVessels}`;
    return this._api
      .get(url)
      .then((res) => new GeolocatedVesselsResponseDTO(res));
  }

  downloadFinishedGoods({ queryParams }) {
    const url = `${
      this._apiGetDeliveriesGeolocation
    }/export${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  downloadVesselVins({ queryParams }) {
    const url = `${
      this._apiGetGeolocatedVessels
    }/download${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new DeliveriesGeolocationRepository({
  api: api,
  apiGetDeliveriesGeolocation: API_GET_GEOLOCATION,
  apiGetFinishedGood: API_GET_SEARCH_FINISHED_GOOD,
  apiGetFinishedGoodTimeline: API_GET_GEOLOCATION_FINISHED_GOOD_TIMELINE,
  apiGetFinishedGoodTransportDetails:
    API_GET_GEOLOCATION_FINISHED_GOOD_TRANSPORT_DETAILS,
  apiGetTransportDetails: API_GET_TRANSPORT_DETAILS,
  apiGetGeolocatedTransports: API_GET_GEOLOCATED_TRANSPORTS,
  apiGetGeolocatedVessels: API_GET_GEOLOCATED_VESSELS
});

export { DeliveriesGeolocationRepository };
