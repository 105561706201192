import { IServiceLevelRepository } from '../domain/IServiceLevelRepository';
import { serviceLevelRepository } from '../infrastructure/repositories/ServiceLevelRepository';

class GetServiceLevelDashboardUseCase {
  private readonly _repository: IServiceLevelRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<string> {
    return this._repository.getServiceLevelDashboardUrl();
  }
}

export const getServiceLevelDashboardUseCase =
  new GetServiceLevelDashboardUseCase({
    repository: serviceLevelRepository
  });

export { GetServiceLevelDashboardUseCase };
