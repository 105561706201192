import FinishedGoods from 'modules/outbound/fulfillment/domain/FinishedGoods';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';
import { IFinishedGoodsDTO } from '../infrastructure/dto/FinishedGoodsListDTO';

class FinishedGoodsByCheckpointService {
  private readonly _deliveriesGeolocationRepository: ITransitRepository;

  constructor({ deliveriesGeolocationRepository }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
  }

  get({
    countryCode,
    checkpointRef,
    queryParams
  }: {
    countryCode: string;
    checkpointRef: string;
    queryParams: object;
  }) {
    return this._deliveriesGeolocationRepository.getFinishedGoodsByCheckpoint!({
      countryCode,
      checkpointRef,
      queryParams
    })
      .then((res) => ({
        countryCode: res.countryCode,
        checkpointRef: res.checkpointRef,
        totalFinishedGoods: res.totalFinishedGoods,
        finishedGoods: res.finishedGoods.map((item) =>
          this._mapToFinishedGoods(item)
        )
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToFinishedGoods(dto: IFinishedGoodsDTO): FinishedGoods {
    return FinishedGoods.generateFromDTO(dto);
  }
}

export default new FinishedGoodsByCheckpointService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository
});

export { FinishedGoodsByCheckpointService };
