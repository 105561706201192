import ContainerNeedsData from '../domain/ContainerNeeds';
import ITransferRepository from '../domain/ITransferRepository';
import TransferRepository from '../infrastructure/repositories/TransferRepository';

class ContainerNeedsUseCase {
  private readonly _containerNeedsRepository: ITransferRepository;

  constructor({ containerNeedsRepository }) {
    this._containerNeedsRepository = containerNeedsRepository;
  }

  execute({ isNextHours }, { queryParams }: { queryParams: object }) {
    return this._containerNeedsRepository
      .getContainerNeeds(isNextHours, { queryParams })
      .then((res) => ContainerNeedsData.generateFromDTO(res));
  }
}

export default new ContainerNeedsUseCase({
  containerNeedsRepository: TransferRepository
});

export { ContainerNeedsUseCase };
