import IUserInboundRepository from '../../domain/UserInbound/IUserInboundRepository';
import InboundUserRepository from '../../infraestructure/repositories/InboundUserRepository';

class PostLoginWebUseCase {
  private readonly _userInboundRepository: IUserInboundRepository;

  constructor({ repository }) {
    this._userInboundRepository = repository;
  }

  execute() {
    return this._userInboundRepository.postLoginWeb();
  }
}

export { PostLoginWebUseCase };
export default new PostLoginWebUseCase({
  repository: InboundUserRepository
});
