import CallOffTrackingByWarehouseDTO from 'modules/outbound/callOff/infrastructure/dto/CallOffTrackingByWarehouseDTO';
import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import callOffRepository from 'modules/outbound/callOff/infrastructure/repositories/CallOffRepository';
import CallOffTracking from 'modules/outbound/callOff/domain/CallOffTracking';

class GetCallOffTrackingService {
  private readonly _callOffRepository: ICallOffRepository;

  constructor({ repository }) {
    this._callOffRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._callOffRepository
      .getTracking({ queryParams })
      .then((res) => this._mapToCallOffTracking(res));
  }

  private _mapToCallOffTracking(response: CallOffTrackingByWarehouseDTO[]) {
    return response.map((item) => CallOffTracking.generateFromDTO(item));
  }
}

export default new GetCallOffTrackingService({
  repository: callOffRepository
});

export { GetCallOffTrackingService };
