import { HandlingUnits } from '../domain/HandlingUnitsData';
import { IExpirationRepository } from '../domain/IExpirationRepository';
import expirationRepository from '../infrastructure/repositories/ExpirationRepository';

class GetHandlingUnitsUseCase {
  private readonly _repository: IExpirationRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<HandlingUnits> {
    return this._repository
      .getHandlingUnits({ queryParams })
      .then((res) => HandlingUnits.generateFromDto(res));
  }
}

const getHandlingUnitsUseCase = new GetHandlingUnitsUseCase({
  repository: expirationRepository
});

export { GetHandlingUnitsUseCase, getHandlingUnitsUseCase };
