import ILoadsRepository from '../domain/ILoadsRepository';
import LoadStatus from '../domain/LoadsStatus';
import TodayLoads from '../domain/TodayLoads';
import { loadsRepository } from '../infrastructure/repositories/LoadsRepository';

class GetLoadsStatusUseCase {
  private readonly _repository: ILoadsRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<{
    loadsStatus: LoadStatus;
    todayLoads: TodayLoads;
  }> {
    return this._repository.getLoadsStatus().then((response) => ({
      loadsStatus: LoadStatus.generateFromDto(response.loadsStatus),
      todayLoads: TodayLoads.generateFromDto(response.todayLoads)
    }));
  }
}

export const getLoadsStatusUseCase = new GetLoadsStatusUseCase({
  repository: loadsRepository
});

export { GetLoadsStatusUseCase };
