import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IDeliveryPointsRepository from 'modules/outbound/shared/domain/IDeliveryPointsRepository';
import { API_GET_DELIVERY_POINTS } from './urls';
import CountryDeliveryPointsDTO from '../dto/CountryDeliveryPointsDTO';

class DeliveryPointsRepository
  extends BaseRepository
  implements IDeliveryPointsRepository
{
  private readonly _api: IApi;
  private readonly _apiGetDeliveryPoints: string;

  constructor({ api, apiGetDeliveryPoints }) {
    super();
    this._api = api;
    this._apiGetDeliveryPoints = apiGetDeliveryPoints;
  }

  getDeliveryPoints(): Promise<CountryDeliveryPointsDTO[]> {
    return this._api
      .get(this._apiGetDeliveryPoints)
      .then((response) =>
        response.map((element) => new CountryDeliveryPointsDTO(element))
      );
  }
}

export default new DeliveryPointsRepository({
  api: api,
  apiGetDeliveryPoints: API_GET_DELIVERY_POINTS
});

export { DeliveryPointsRepository };
