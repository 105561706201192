import ICommercialPackagesRepository from '../domain/ICommercialPackagesRepository';
import commercialPackagesRepository from '../infrastructure/repositories/CommercialPackagesRepository';

class GetCommercialPackagesUseCase {
  private readonly _repository: ICommercialPackagesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute() {
    return this._repository.getCommercialPackages();
  }
}

export default new GetCommercialPackagesUseCase({
  repository: commercialPackagesRepository
});

export { GetCommercialPackagesUseCase };
