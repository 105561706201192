class GetWarehouseByModelService {
  private readonly _allWarehouseReference: string;

  constructor() {
    this._allWarehouseReference = 'All';
  }

  _getReference(warehouseReference?: string): string {
    return warehouseReference ?? this._allWarehouseReference;
  }
}

export { GetWarehouseByModelService };
