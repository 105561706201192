const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const STOCK_ENDPOINT = '/stock/coverage';
const FILTERS_ENDPOINT = '/filters';
const COMMENT_ENDPOINT = '/comment';
const NOJIT_COVERAGE_AT_RISK_ENDOINT = `/nojit/coverage/models`;
const API_GET_STOCK_BY_PROVIDER = (providerName: string) =>
  `${INBOUND_BASE_URL}${STOCK_ENDPOINT}/${providerName}`;
const API_GET_NOJIT_FILTERS = `${INBOUND_BASE_URL}${FILTERS_ENDPOINT}${STOCK_ENDPOINT}`;
const API_GET_NOJIT_COVERAGE_AT_RISK = `${INBOUND_BASE_URL}${NOJIT_COVERAGE_AT_RISK_ENDOINT}`;
const API_DOWNLOAD_NOJIT = `${INBOUND_BASE_URL}/nojit/download`;
const API_GET_NOJIT_PLANNING = `${INBOUND_BASE_URL}/nojit/planning`;
const API_PUT_COMMENT = (code: string) =>
  `${INBOUND_BASE_URL}/nojit/${code}${COMMENT_ENDPOINT}`;

export {
  API_GET_STOCK_BY_PROVIDER,
  API_GET_NOJIT_FILTERS,
  API_GET_NOJIT_COVERAGE_AT_RISK,
  API_DOWNLOAD_NOJIT,
  API_GET_NOJIT_PLANNING,
  API_PUT_COMMENT
};
