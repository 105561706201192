import { Translation } from 'infrastructure/i18n/typing';
import { HeaderTranslations } from './typing';

export const HeaderTexts: Translation<HeaderTranslations> = {
  es: {
    appName: 'Control Tower',
    title: 'Tránsitos a Pasado',
    master: 'Maestros',
    users: 'Usuarios',
    userManagement: 'Gestión de usuarios',
    management: 'Gestión',
    masterJIT: 'Maestro JIT',
    warehouseCapacity: 'Capacidad del Almacén',
    lineGroups: 'Grupos de Línea',
    draftTitle: 'DRAFT',
    calendar: 'Calendario montaje línea',
    factoryBCN: 'Factoría BCN',
    masterBodyshopDD: 'Programa Chapa DD',
    masterCodeWorkshop: 'Clave Taller Factoría BCN',
    outbound: 'Outbound',
    landing: 'Landings',
    transitsToPast: 'Tránsitos a pasado',
    geolocation: 'Geolocalización',
    geolocationMap: 'Mapa de geolocalización',
    midTermCapacities: 'Medio Plazo',
    longTermCapacities: 'Largo Plazo',
    sales: 'Comercial',
    warehouseStock: 'Gestión de stock',
    pipelineStock: 'Pipeline stock',
    transits: 'Panel de tránsitos',
    deliveryTime: 'Tiempos de entrega',
    inbound: 'Inbound',
    noJit: 'NOJIT',
    jit: 'JIT',
    jitFamilyCoverages: 'Coberturas familia JIT',
    partCoverage: 'Cobertura pieza',
    familyCoverage: 'Paro línea familia',
    directDelivery: 'Direct delivery',
    supplies: 'Suministros',
    transfers: 'Trasvases',
    refills: 'Reposiciones',
    warehouses: 'Almacenes',
    endOfProductionDate: 'Ceses',
    entries: 'Entradas',
    entriesNextHours: 'Próximas 24 horas',
    entriesInPlant: 'Actualmente en planta',
    inhouse: 'Inhouse',
    inboundInhouse: 'Inbound - Inhouse',
    masterBodyshopCalendar: 'Calendario Chapa',
    materialInTransfer: 'Material en zona Trasvase',
    transferNextHours: 'Llegadas próximas 8h',
    expiration: 'Caducidades',
    serviceLevel: 'Nivel de Servicio',
    entriesUnloadingPoints: 'Por punto de descarga',
    stocks: 'Stocks',
    mastersPanel: 'Panel Maestros',
    noCreditCarsLoan: 'Coches sin crédito',
    forecastPortStocks: 'Forecast stocks puerto',
    stucklisteSuppliers: 'Stuckliste proveedores',
    capacities: 'Capacidades',
    analytics: 'Analytics',
    stockLocation: 'Stock location',
    dataQuality: 'Calidad del dato',
    campaMRTState: 'Estado Campa MRT (5.45h)'
  },
  en: {
    appName: 'Control Tower',
    title: 'Transit to Past',
    master: 'Master',
    users: 'Users',
    userManagement: 'User management',
    management: 'Management',
    masterJIT: 'Master JIT',
    warehouseCapacity: 'Warehouse Capacity',
    lineGroups: 'Line Groups',
    draftTitle: 'DRAFT',
    calendar: 'Line assembly calendar',
    factoryBCN: 'Factory BCN',
    masterBodyshopDD: 'DD Bodyshop',
    masterCodeWorkshop: 'Code Workshop Factory BCN',
    outbound: 'Outbound',
    landing: 'Landings',
    transitsToPast: 'Transits to past',
    geolocation: 'Geolocation',
    geolocationMap: ' Geolocation map',
    midTermCapacities: 'Mid Term',
    longTermCapacities: 'Long Term',
    sales: 'Sales',
    warehouseStock: 'Warehouse stock',
    pipelineStock: 'Pipeline stock',
    transits: 'Transit panel',
    deliveryTime: 'Delivery times',
    inbound: 'Inbound',
    noJit: 'NOJIT',
    jit: 'JIT',
    jitFamilyCoverages: 'Family coverages JIT',
    partCoverage: 'Part coverage',
    familyCoverage: 'Family line stop',
    directDelivery: 'Direct delivery',
    supplies: 'Supplies',
    transfers: 'Transfers',
    refills: 'Refills',
    warehouses: 'Warehouses',
    endOfProductionDate: 'End of production date',
    entries: 'Entries',
    entriesNextHours: 'Next 24 hours',
    entriesInPlant: 'Currently in plant',
    inhouse: 'Inhouse',
    inboundInhouse: 'Inbound - Inhouse',
    masterBodyshopCalendar: 'Bodyshop Calendar',
    materialInTransfer: 'Material in Transfer zone',
    transferNextHours: 'Next 8 hours arrivals',
    expiration: 'Expiration',
    serviceLevel: 'Service Level',
    entriesUnloadingPoints: 'By unload point',
    stocks: 'Stocks',
    mastersPanel: 'Masters Panel',
    noCreditCarsLoan: 'No credit cars loan',
    forecastPortStocks: 'Forecast port stocks',
    stucklisteSuppliers: 'Stucklist suppliers',
    capacities: 'Capacities',
    analytics: 'Analytics',
    stockLocation: 'Stock location',
    dataQuality: 'Data quality',
    campaMRTState: 'State Campa MRT (5.45h)'
  },
  ca: {
    appName: 'Control Tower',
    title: 'Transitos a Pasado',
    master: 'Mestres',
    users: 'Usuaris',
    userManagement: "Gestió d'usuaris",
    management: 'Gestió',
    masterJIT: 'Mestre JIT',
    warehouseCapacity: 'Capacitat del Magatzem',
    lineGroups: 'Grups de Línia',
    draftTitle: 'DRAFT',
    calendar: 'Calendari muntatge línia',
    factoryBCN: 'Factory BCN',
    masterBodyshopDD: 'Programa Xapa DD',
    masterCodeWorkshop: 'Clau Taller Factoria BCN',
    outbound: 'Outbound',
    landing: 'Landings',
    transitsToPast: 'Trànsits a passat',
    geolocation: 'Geolocalizació',
    geolocationMap: 'Mapa de geolocalizació',
    midTermCapacities: 'Mitjà Termini',
    longTermCapacities: 'Llarg Termini',
    sales: 'Comercial',
    warehouseStock: 'Gestió de stock',
    pipelineStock: 'Pipeline stock',
    transits: 'Panell de trànsits',
    deliveryTime: "Temps d'entrega",
    inbound: 'Inbound',
    noJit: 'NOJIT',
    jit: 'JIT',
    jitFamilyCoverages: 'Cobertures família JIT',
    partCoverage: 'Cobertura peça',
    familyCoverage: 'Atur línia família',
    directDelivery: 'Direct delivery',
    supplies: 'Suministres',
    transfers: 'Transvasaments',
    refills: 'Reposicions',
    warehouses: 'Magatzems',
    endOfProductionDate: 'Cessament',
    entries: 'Entrades',
    entriesNextHours: 'Properes 24 hores',
    entriesInPlant: 'Actualment a la planta',
    inhouse: 'Inhouse',
    inboundInhouse: 'Inbound - Inhouse',
    masterBodyshopCalendar: 'Calendari Xapa',
    materialInTransfer: 'Material a zona Trasvase',
    transferNextHours: 'Arrivades pròximes 8h',
    expiration: 'Caducitats',
    serviceLevel: 'Nivell de Servei',
    entriesUnloadingPoints: 'Per punt de descàrrega',
    stocks: 'Estocs',
    mastersPanel: 'Panell Mestres',
    noCreditCarsLoan: 'Cotxes sense crédit',
    forecastPortStocks: 'Forecast estocs port',
    stucklisteSuppliers: 'Stuckliste proveïdors',
    capacities: 'Capacitats',
    analytics: 'Analytics',
    stockLocation: 'Ubicació stock',
    dataQuality: 'Qualitat de la dada',
    campaMRTState: 'Estat Campa MRT (5.45h)'
  }
};
