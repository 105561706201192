import ISuppliesRepository from '../domain/ISuppliesRepository';
import SuppliesStatus from '../domain/SuppliesStatus';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetSuppliesClosedStatusService {
  private readonly _suppliesRepository: ISuppliesRepository;

  constructor({ suppliesRepository }) {
    this._suppliesRepository = suppliesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._suppliesRepository
      .getClosedStatus({ queryParams })
      .then((res) => SuppliesStatus.generateFromDTO(res));
  }
}

export default new GetSuppliesClosedStatusService({
  suppliesRepository: suppliesRepository
});

export { GetSuppliesClosedStatusService };
