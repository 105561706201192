import FactoryGeolocationDelay from '../domain/FactoryDelay';
import IFactoryDelayRepository from '../domain/IFactoryDelayRepository';
import factoryDelayRepository from '../infrastructure/repositories/FactoryDelayRepository';

class GetFactoryDelayUseCase {
  private readonly _repository: IFactoryDelayRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<FactoryGeolocationDelay> {
    return this._repository
      .getFactoryDelay()
      .then((response) => FactoryGeolocationDelay.generateFromDto(response));
  }
}

export default new GetFactoryDelayUseCase({
  repository: factoryDelayRepository
});

export { GetFactoryDelayUseCase };
