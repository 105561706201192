import ISuppliesRepository from '../domain/ISuppliesRepository';
import SummaryCriticalStockData from '../../shared/domain/SummaryCriticalStock';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetSuppliesCriticalStockSummaryUseCase {
  private readonly _suppliesRepository: ISuppliesRepository;

  constructor({ suppliesRepository }) {
    this._suppliesRepository = suppliesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._suppliesRepository
      .getCriticalStockSummary({ queryParams })
      .then((res) => SummaryCriticalStockData.generateFromDTO(res));
  }
}

export default new GetSuppliesCriticalStockSummaryUseCase({
  suppliesRepository: suppliesRepository
});

export { GetSuppliesCriticalStockSummaryUseCase };
