import { IEntriesRepository } from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';
import EntryDetail from '../../domain/NextHours/EntriesDetail';
import { EntriesData } from '../../domain/NextHours/entriesDataMaper';

class GetEntriesDataUseCase {
  private readonly _entriesDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesDataRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }): Promise<EntriesData> {
    return this._entriesDataRepository.getEntriesStock({ queryParams });
  }

  getDetail({ transportId }: { transportId: number }): Promise<EntryDetail> {
    return this._entriesDataRepository
      .getEntryDetail({ transportId })
      .then((res) => EntryDetail.generateFromDTO(res));
  }
}

export default new GetEntriesDataUseCase({
  repository: entriesRepository
});

export { GetEntriesDataUseCase };
