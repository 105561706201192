import { API_GET_PIPELINE } from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import PipelineStockResponseDTO from 'modules/outbound/stock/infrastructure/dto/PipelineStockResponseDTO';
import PipelineDetailedCarInfoDTO from 'modules/outbound/stock/infrastructure/dto/PipelineDetailedCarInfoDTO';
import IPipelineStockRepository from 'modules/outbound/stock/domain/IPipelineStockRepository';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';

class PipelineStockRepository
  extends BaseRepository
  implements IPipelineStockRepository
{
  private readonly _api: IApi;
  private readonly _apiGetPipelineStock: string;

  constructor({ api, apiGetPipelineStock }) {
    super();
    this._api = api;
    this._apiGetPipelineStock = apiGetPipelineStock;
  }

  get({ queryParams }) {
    const url = `${this._apiGetPipelineStock}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => new PipelineStockResponseDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getDetailedInfo({ reference, queryParams }) {
    const url = `${
      this._apiGetPipelineStock
    }/${reference}${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => new PipelineDetailedCarInfoDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  downloadPipelineStock({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetPipelineStock}/export${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new PipelineStockRepository({
  api: api,
  apiGetPipelineStock: API_GET_PIPELINE
});

export { PipelineStockRepository };
