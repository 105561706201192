import { DateRangeParams } from 'modules/outbound/fulfillment/domain/ITransitRepository';

export enum DateRangeType {
  MTD = 'MTD',
  YTD = 'YTD',
  WTD = 'WTD',
  AMTD = 'AMTD',
  AYTD = 'AYTD',
  L7D = 'L7D',
  CWTD = 'CWTD'
}
class GetDateRangeService {
  private readonly _currentDate: number;

  constructor({ currentDate }) {
    this._currentDate = currentDate;
  }

  /**
   *
   * @param startDate Date
   * @param endDate Date
   * @returns DateRangeParams
   * get a formated date range from two diffferent dates
   */
  formatDateFromDates(startDate: Date, endDate: Date): DateRangeParams {
    if (startDate < endDate) {
      const fromDate = this._formatFromDate(startDate);
      const toDate = this._formatFromDate(endDate);
      return { fromDate, toDate };
    }
    console.error('invalid Dates calculation for date range'); // eslint-disable-line no-console
    return { fromDate: '', toDate: '' };
  }
  /**
   *
   * @param selectedDateRange DateRangeType
   * @returns DateRangeParams
   * get a date range from a DateRangeType option and this._currentDate
   */
  formatDateFromRange(selectedDateRange): DateRangeParams {
    if (selectedDateRange === DateRangeType.MTD) {
      return this._getMTD();
    } else if (selectedDateRange === DateRangeType.AMTD) {
      return this._getActualMonth();
    } else if (selectedDateRange === DateRangeType.YTD) {
      return this._getYTD();
    } else if (selectedDateRange === DateRangeType.AYTD) {
      return this._getActualYear();
    } else if (selectedDateRange === DateRangeType.L7D) {
      return this._getLastSevenDays();
    } else if (selectedDateRange === DateRangeType.CWTD) {
      return this._getCurrentWeek();
    }

    return this._getWTD();
  }

  /**
   * @returns DateRangeParams
   * Get the start date and the end date of the actual month given the date: this._currentDate
   */
  private _getActualMonth(): DateRangeParams {
    const monthFirstDay = '01';
    const currentDate = new Date(this._currentDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const validCurrentMonth = currentMonth !== 12 ? currentMonth + 1 : 0;
    const toDateValues = [
      currentYear,
      this._formatTwoDigitNumber(validCurrentMonth),
      this._formatTwoDigitNumber(currentDay)
    ];
    const toDate = toDateValues.join('-');

    const fromDateValues = [
      currentYear,
      this._formatTwoDigitNumber(validCurrentMonth),
      monthFirstDay
    ];

    const fromDate = fromDateValues.join('-');

    if (fromDateValues[2] <= toDateValues[2]) {
      return { fromDate, toDate };
    }

    console.error('invalid Dates calculation for MTD'); // eslint-disable-line no-console
    return { fromDate: '', toDate: '' };
  }
  /**
   * @returns DateRangeParams
   * Get the start date and the end date of the last month given the date: this._currentDate
   */
  private _getMTD(): DateRangeParams {
    const monthFirstDay = '01';
    const currentDate = new Date(this._currentDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const lastMonth = currentMonth !== 0 ? currentMonth : 12;
    const calculatedYear = currentMonth !== 0 ? currentYear : currentYear - 1;

    const toDateValues = [
      calculatedYear,
      this._formatTwoDigitNumber(lastMonth),
      this._formatTwoDigitNumber(
        this._getLastDayOfMonth(calculatedYear, lastMonth)
      )
    ];
    const toDate = toDateValues.join('-');

    const fromDateValues = [
      calculatedYear,
      this._formatTwoDigitNumber(lastMonth),
      monthFirstDay
    ];

    const fromDate = fromDateValues.join('-');

    if (fromDateValues[2] < toDateValues[2]) {
      return { fromDate, toDate };
    }

    console.error('invalid Dates calculation for MTD'); // eslint-disable-line no-console
    return { fromDate: '', toDate: '' };
  }

  /**
   * @returns DateRangeParams
   * Get the start date and the end date of the actual year given the date: this._currentDate
   */
  private _getActualYear(): DateRangeParams {
    const yearFirstDay = '01';
    const yearFirstMonth = '01';
    const currentDate = new Date(this._currentDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const validCurrentMonth = currentMonth !== 12 ? currentMonth + 1 : 0;
    const toDateValues = [
      currentYear,
      this._formatTwoDigitNumber(validCurrentMonth),
      this._formatTwoDigitNumber(currentDay)
    ];
    const toDate = toDateValues.join('-');

    const fromDateValues = [currentYear, yearFirstMonth, yearFirstDay];

    const fromDate = fromDateValues.join('-');

    return { fromDate, toDate };
  }
  /**
   * @returns DateRangeParams
   * Get the start date and the end date of the last year given the date: this._currentDate
   */
  private _getYTD(): DateRangeParams {
    const yearFirstDay = '01';
    const yearFirstMonth = '01';
    const yearLastDay = '31';
    const yearLastMonth = '12';
    const currentDate = new Date(this._currentDate);
    const currentYear = currentDate.getFullYear();

    const calculatedYear = currentYear - 1;

    const toDateValues = [calculatedYear, yearLastMonth, yearLastDay];
    const toDate = toDateValues.join('-');

    const fromDateValues = [calculatedYear, yearFirstMonth, yearFirstDay];
    const fromDate = fromDateValues.join('-');

    return { fromDate, toDate };
  }

  /**
   * @returns DateRangeParams
   * Get the start date and the end date of the last week given the date: this._currentDate
   */
  private _getWTD(): DateRangeParams {
    let toDateValue = new Date(this._currentDate);
    const lastSunday = toDateValue.getDate() - toDateValue.getUTCDay();
    toDateValue.setDate(lastSunday);

    let fromDateValue = new Date(this._currentDate);
    const lastMonday = fromDateValue.getDate() - fromDateValue.getUTCDay() - 6;
    fromDateValue.setDate(lastMonday);

    const fromDate = this._formatFromDate(fromDateValue);

    const toDate = this._formatFromDate(toDateValue);

    if (fromDateValue < toDateValue) {
      return { fromDate, toDate };
    }

    console.error('invalid Dates calculation for WTD'); // eslint-disable-line no-console
    return { fromDate: '', toDate: '' };
  }

  /**
   * @returns DateRangeParams
   * Get the last seven days range given the date: this._currentDate
   */
  private _getLastSevenDays(): DateRangeParams {
    let toDateValue = new Date(this._currentDate);

    let fromDateValue = new Date(this._currentDate);

    const sevenDaysBefore = fromDateValue.getDate() - 7;
    fromDateValue.setDate(sevenDaysBefore);

    const fromDate = this._formatFromDate(fromDateValue);

    const toDate = this._formatFromDate(toDateValue);

    if (fromDateValue < toDateValue) {
      return { fromDate, toDate };
    }

    console.error('invalid Dates calculation for L7D'); // eslint-disable-line no-console
    return { fromDate: '', toDate: '' };
  }

  /**
   * @returns DateRangeParams
   * Get the last seven days range given the date: this._currentDate
   */
  private _getCurrentWeek(): DateRangeParams {
    let toDateValue = new Date(this._currentDate);
    const toDate = this._formatFromDate(toDateValue);

    const fromDateValue = this._getMondayOfCurrentWeek(toDateValue);
    const fromDate = this._formatFromDate(fromDateValue);

    return { fromDate, toDate };
  }

  private _getMondayOfCurrentWeek(currentDate) {
    const first = currentDate.getDate() - currentDate.getDay() + 1;

    return new Date(currentDate.setDate(first));
  }

  /**
   *
   * @param year number
   * @param month number
   * @returns number
   * get last day of a month given a year and a month
   */
  private _getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  /**
   *
   * @param num number
   * @returns string
   * create a two digit number from a 1 digit one, adding a 0 at the beginning
   */
  private _formatTwoDigitNumber(num) {
    return String(num).padStart(2, '0');
  }

  private _formatFromDate(date) {
    const dateValues = [
      date.getFullYear(),
      this._formatTwoDigitNumber(date.getMonth() + 1),
      this._formatTwoDigitNumber(date.getDate())
    ];
    return dateValues.join('-');
  }
}

export default new GetDateRangeService({ currentDate: Date.now() });

export { GetDateRangeService };
