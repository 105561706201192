import { IExpirationRepository } from '../domain/IExpirationRepository';
import expirationRepository from '../infrastructure/repositories/ExpirationRepository';

class DownloadExpirationUseCase {
  private readonly _expirationRepository: IExpirationRepository;

  constructor({ expirationRepository }) {
    this._expirationRepository = expirationRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._expirationRepository.getExpirationsDownload({
      queryParams
    });
  }
}

export default new DownloadExpirationUseCase({
  expirationRepository: expirationRepository
});

export { DownloadExpirationUseCase };
