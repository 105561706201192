import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  CoverageSegments,
  LoadTransport,
  MaterialResourcePlan
} from 'modules/inbound/shared/domain/PlanningTransports';
import {
  LoadTransportDTO,
  MaterialResourcePlanDTO
} from 'modules/inbound/shared/infraestructure/dto/PlanningTransportsDTO';
import { NoJitPlanningDTO } from '../infrastructure/dto/noJitPlanningDTOMapper';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { StatusPlanType } from 'modules/inbound/shared/domain/StatusPlanTypes';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';

const noJitTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};

export const getStatusPlanTranslation = (type: StatusPlanType) => {
  if (type === StatusPlanType.TransportPending) {
    return noJitTranslations.tp;
  }
  if (type === StatusPlanType.TransportInTransit) {
    return noJitTranslations.tr;
  }
  if (type === StatusPlanType.TransportInPlant) {
    return noJitTranslations.ep;
  }
};
export interface NoJitItemPlanning {
  date: string | null;
  amount: number;
  loads: LoadTransport[];
  coverageSegments: CoverageSegments[];
  mrps: MaterialResourcePlan[];
}

export interface INoJitPlanning {
  [key: string]: {
    currentPlanning: NoJitItemPlanning[];
    pastLoads: string[];
  };
}

interface TransportsAndMrps {
  keysWithTransports: string[];
  keysWithMrps: string[];
}
class NoJitPlanning {
  planning: INoJitPlanning;
  KeysContainingTransportsAndMrps: TransportsAndMrps;

  constructor({ planning, KeysContainingTransportsAndMrps }: NoJitPlanning) {
    this.planning = planning;
    this.KeysContainingTransportsAndMrps = KeysContainingTransportsAndMrps;
  }

  private static _formatPlanningLoad(load: LoadTransportDTO): LoadTransport {
    return {
      providerId: load.provider_id,
      plateNumber: load.plate_number,
      amount: load.amount,
      departureDate: load.departure_date
        ? GetDateFormatedService.getFullDateTime(load.departure_date)
        : '-',
      leadtime: load.leadtime,
      officialDeliveryTime: GetDateFormatedService.getFullDateTime(
        load.official_delivery_time.value
      ),
      providerDeliveryTime: GetDateFormatedService.getFullDateTime(
        load.provider_delivery_time
      ),
      source: load.official_delivery_time.source,
      windowBookedId: load.window_booked_id,
      type: load.type,
      plannedDate: load.planned_date
        ? GetDateFormatedService.getShortDateTime(load.planned_date)
        : null,
      providerDeliveryNote: load.provider_delivery_note,
      transporter: load.transporter,
      geolocated: load.official_delivery_time.source === 'GEOLOCATION_ETA',
      isDelayed: load.is_delayed,
      isUrgent: load.is_urgent,
      isKcc: load.is_kcc
    };
  }

  private static _formatPlanningMRP(
    mrp: MaterialResourcePlanDTO,
    planningDate: string
  ): MaterialResourcePlan {
    return {
      providerId: mrp.provider_id,
      amount: mrp.amount,
      planningState: mrp.planning_state,
      leadtime: mrp.leadtime,
      date: planningDate,
      endPlanningDate: GetDateFormatedService.getDateFormated(
        mrp.end_planning_date,
        'es-ES'
      )
    };
  }

  private static _formatPastLoads(pastLoad: LoadTransportDTO): string {
    let pastLoadFormatted: string[] = [];

    pastLoadFormatted.push(
      `#${pastLoad.provider_id}`,
      pastLoad.type,
      pastLoad.amount.toString(),
      pastLoad.official_delivery_time
        ? GetDateFormatedService.getFullDateTime(
            pastLoad.official_delivery_time.value
          )
        : ' - ',
      pastLoad.plate_number
    );
    return pastLoadFormatted.join(' | ');
  }

  private static _generatePlanning(planning: NoJitPlanningDTO): INoJitPlanning {
    if (!planning) return {};

    const planningKeys: string[] = Object.keys(planning);
    const formatPlanning: INoJitPlanning = planningKeys.reduce(
      (acc, curr) => (
        (acc[curr] = { currentPlanning: [], pastLoads: [] }), acc
      ),
      {}
    );

    if (planningKeys) {
      planningKeys.forEach((code) => {
        let planningByPiece = planning[code].planning;
        if (planningByPiece) {
          planningByPiece.forEach((planningItem) => {
            formatPlanning[code].currentPlanning.push({
              date: planningItem.date,
              amount: planningItem.amount,
              coverageSegments: planningItem.coverage_segments,
              loads: planningItem.loads?.map((load) =>
                this._formatPlanningLoad(load)
              ),
              mrps: planningItem.mrps?.map((mrp) =>
                this._formatPlanningMRP(mrp, planningItem.date)
              )
            });
          });
        }

        let pastLoadsByPiece = planning[code].past_loads;
        if (pastLoadsByPiece) {
          pastLoadsByPiece.forEach((pastLoad) => {
            formatPlanning[code].pastLoads.push(
              this._formatPastLoads(pastLoad)
            );
          });
        }
      });

      return formatPlanning;
    }
    return {};
  }

  private static _getKeysWithTransports(
    planning: NoJitPlanningDTO
  ): TransportsAndMrps {
    const planningKeys: string[] = Object.keys(planning);
    const KeysContainingTransportsAndMrps: TransportsAndMrps = {
      keysWithTransports: [],
      keysWithMrps: []
    };

    if (planningKeys) {
      planningKeys.forEach((code) => {
        let planningByPiece = planning[code]['planning'];
        if (planningByPiece) {
          planningByPiece.forEach((planningItem) => {
            if (planningItem.loads.length) {
              KeysContainingTransportsAndMrps.keysWithTransports.push(code);
            }
            if (planningItem.mrps.length) {
              KeysContainingTransportsAndMrps.keysWithMrps.push(code);
            }
          });
        }
      });
      return KeysContainingTransportsAndMrps;
    }
    return KeysContainingTransportsAndMrps;
  }

  static generateFromDTO(planning: NoJitPlanningDTO): NoJitPlanning {
    return new NoJitPlanning({
      planning: this._generatePlanning(planning),
      KeysContainingTransportsAndMrps: this._getKeysWithTransports(planning)
    });
  }
}

export { NoJitPlanning };
