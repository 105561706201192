/* eslint-disable no-console */
import * as Auth from 'aws-amplify/auth';
import IAuthenticationService from 'modules/shared/domain/IAuthenticationService';
import { UserGroup } from 'modules/shared/domain/UserRole';
import { initLogger } from 'infrastructure/logger';

export class CognitoAuthenticationService implements IAuthenticationService {
  _authClient: typeof Auth;
  _idToken?: string | null;

  constructor({ authClient }) {
    this._authClient = authClient;
  }
  initSession({ username, password }: { username: string; password: string }) {
    return this._authClient
      .signIn({ username, password })
      .then((signInOutput) => {
        this.checkSession();
        initLogger();
        return { signInOutput: signInOutput, username };
      })
      .catch((error) => {
        console.log('error signing in', error);
        throw new Error(error);
      });
  }

  logout() {
    return this._authClient.signOut();
  }

  getIdToken() {
    return this._idToken ?? '';
  }

  checkSession() {
    return this._authClient
      .fetchAuthSession()
      .then((data) => {
        this._idToken = data.tokens?.idToken?.toString();
        return true;
      })
      .catch(() => {
        this.logout();
        return false;
      });
  }

  getUsername() {
    return this._authClient
      .getCurrentUser()
      .then((user) => {
        return user.username;
      })
      .catch(() => {
        this.logout();
        return '';
      });
  }

  getUserEmail() {
    return this._authClient
      .fetchUserAttributes()
      .then(({ email }) => {
        return email;
      })
      .catch(() => {
        return '';
      });
  }

  getUserGroup(): Promise<UserGroup[]> {
    return this._authClient
      .fetchAuthSession()
      .then((session: Auth.AuthSession) => {
        const userGroupsList: UserGroup[] = session?.tokens?.accessToken
          .payload['cognito:groups'] as UserGroup[];

        if (userGroupsList) {
          userGroupsList
            .filter((userGroup) => Object.values(UserGroup).includes(userGroup))
            .forEach((userGroup) => UserGroup[userGroup]);
          return userGroupsList.length ? userGroupsList : [UserGroup.NONE];
        }

        return [];
      })
      .catch(() => {
        return [UserGroup.NONE];
      });
  }

  forgotPassword({ username }: { username: string }) {
    const handleResetPasswordNextSteps = (output: Auth.ResetPasswordOutput) => {
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          console.log(
            `Confirmation code was sent to ${nextStep.codeDeliveryDetails}`
          );
          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          break;
      }
    };

    return this._authClient
      .resetPassword({ username })
      .then((output) => handleResetPasswordNextSteps(output))
      .catch((error) => {
        console.log('error', error);
        throw Error(error.code);
      });
  }

  forgotPasswordSubmit({
    username,
    code,
    password
  }: {
    username: string;
    code: string;
    password: string;
  }) {
    return this._authClient
      .confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword: password
      })
      .catch((error) => {
        console.log('error submit', error);
        throw Error(error.code);
      });
  }

  completeNewPassword({ newPassword }: { newPassword: string }) {
    return this._authClient
      .confirmSignIn({ challengeResponse: newPassword })
      .then((confirmSignInOutput) => confirmSignInOutput)
      .catch((error) => {
        console.log('error new password', error);
      });
  }
}

export default new CognitoAuthenticationService({
  authClient: Auth
});
