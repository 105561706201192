import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import UpcomingTransitsData from '../domain/UpcomingTransitsData';
import DirectDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class UpcomingTransitsService {
  private readonly _upcomingTransitsRepository: IDirectDeliveryRepository;

  constructor({ upcomingTransitsRepository }) {
    this._upcomingTransitsRepository = upcomingTransitsRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._upcomingTransitsRepository
      .getUpcomingTransits({ queryParams })
      .then((res) => UpcomingTransitsData.generateFromDTO(res));
  }
}

export default new UpcomingTransitsService({
  upcomingTransitsRepository: DirectDeliveryRepository
});

export { UpcomingTransitsService };
