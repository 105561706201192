import EntriesFiltersData from '../../domain/NextHours/EntriesFilters';
import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesFiltersService {
  private readonly _entriesRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesRepository = repository;
  }

  execute() {
    return this._entriesRepository.getEntriesFilters().then((res) => {
      return EntriesFiltersData.generateFromDTO(res);
    });
  }
}

export default new GetEntriesFiltersService({
  repository: entriesRepository
});

export { GetEntriesFiltersService };
