import IRefillsRepository from '../domain/IRefillsRepository';
import RefillsStatusData from '../domain/RefillsStatusData';
import refillsRepository from '../infrastructure/repositories/RefillsRepository';

class GetRefillsStatusDataService {
  private readonly _refillsRepository: IRefillsRepository;

  constructor({ repository }) {
    this._refillsRepository = repository;
  }

  execute() {
    return this._refillsRepository
      .getRefillsStatus()
      .then((res) => RefillsStatusData.generateFromDTO(res));
  }
}

export default new GetRefillsStatusDataService({
  repository: refillsRepository
});

export { GetRefillsStatusDataService };
