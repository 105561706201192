import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import { directDeliveriesMapper } from '../domain/directDeliveriesMapper';
import directDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class DirectDeliveryService {
  private readonly _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ directDeliveryRepository }) {
    this._directDeliveryRepository = directDeliveryRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._directDeliveryRepository
      .get({ queryParams })
      .then((res) => directDeliveriesMapper(res));
  }
}

export default new DirectDeliveryService({
  directDeliveryRepository: directDeliveryRepository
});

export { DirectDeliveryService };
