import FactoryBcnFilters from '../domain/FactoryBcnFilters';
import IFactoryBcnDataRepository from '../domain/IFactoryBcnDataRepository';
import factoryBcnRepository from '../infrastructure/repositories/FactoryBCNRepository';

class GetFactoryBcnFiltersUseCase {
  private readonly _factoryBcnRepository: IFactoryBcnDataRepository;

  constructor({ factoryBcnRepository }) {
    this._factoryBcnRepository = factoryBcnRepository;
  }

  execute() {
    return this._factoryBcnRepository
      .getFactoryBcnFilters()
      .then((res) => FactoryBcnFilters.generateFromDTO(res));
  }
}

export { GetFactoryBcnFiltersUseCase };
export default new GetFactoryBcnFiltersUseCase({
  factoryBcnRepository: factoryBcnRepository
});
