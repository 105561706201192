import EndOfProductionFilters from '../domain/EndOfProductionFilters';
import IEndOfProductionDateRepository from '../domain/IEndOfProductionDateRepository';
import endOfProductionDateRepository from '../infrastructure/repositories/EndOfProductionDateRepository';

class GetEndOfProductionFiltersService {
  private readonly _endOfProducitonRepository: IEndOfProductionDateRepository;

  constructor({ endOfProductionRepository }) {
    this._endOfProducitonRepository = endOfProductionRepository;
  }

  execute() {
    return this._endOfProducitonRepository
      .getFilters()
      .then((res) => EndOfProductionFilters.generateFromDTO(res));
  }
}

export { GetEndOfProductionFiltersService };
export default new GetEndOfProductionFiltersService({
  endOfProductionRepository: endOfProductionDateRepository
});
