import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import DirectDeliveryFilters from 'modules/inbound/directDelivery/domain/DirectDeliveryFilters';
import directDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class GetDirectDeliveryFiltersService {
  private readonly _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ repository }) {
    this._directDeliveryRepository = repository;
  }

  execute() {
    return this._directDeliveryRepository
      .getDirectDeliveryFilters()
      .then((res) => {
        return DirectDeliveryFilters.generateFromDTO(res);
      });
  }
}

export default new GetDirectDeliveryFiltersService({
  repository: directDeliveryRepository
});

export { GetDirectDeliveryFiltersService };
