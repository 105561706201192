import { IDeliveryTimeRepository } from 'modules/outbound/deliveryTime/domain/IDeliveryTimeRepository';
import DeliveryTimeSlot from 'modules/outbound/deliveryTime/domain/DeliveryTimeSlot';
import deliveryTimeRepository from 'modules/outbound/deliveryTime/infrastructure/repositories/DeliveryTimeRepository';

class GetDeliveryTimeSlotsUseCase {
  private readonly _repository: IDeliveryTimeRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<DeliveryTimeSlot[]> {
    return this._repository
      .getDeliveryTimeSlots({ queryParams })
      .then((res) => res.map((item) => DeliveryTimeSlot.generateFromDTO(item)));
  }
}

export default new GetDeliveryTimeSlotsUseCase({
  repository: deliveryTimeRepository
});

export { GetDeliveryTimeSlotsUseCase };
