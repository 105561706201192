import { IDateRange } from 'modules/inbound/endOfProductionDate/domain/IEndOfProductionDateRepository';

class GetDateFormatedService {
  private readonly _currentDate: number;

  constructor({ currentDate }) {
    this._currentDate = currentDate;
  }

  /**
   *
   * @param date Date
   * @param locale Local timezone
   * @returns DateFormated
   * get a formated date
   */
  getDateFormated(date, locale) {
    return new Date(date || this._currentDate).toLocaleDateString(locale, {
      day: 'numeric',
      month: '2-digit',
      year: '2-digit'
    });
  }

  getMonthDayFormated(date, locale) {
    return new Date(date || this._currentDate).toLocaleDateString(locale, {
      month: '2-digit',
      day: '2-digit'
    });
  }

  getTimeFormated(date: string | Date) {
    return new Date(date).toLocaleTimeString(['es-ES'], {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   *
   * @param date Date
   * @param locale Local timezone
   * @returns DateFormated
   * get a formated date dd/mm/YY HH:MM
   */
  getShortDateTime(date) {
    return new Date(date).toLocaleString([], {
      dateStyle: 'short',
      timeStyle: 'short'
    });
  }

  /**
   *
   * @param date Date
   * @returns DateFormated
   * get a formated date HH:MM
   */
  getShortHourLocale(date) {
    return new Date(date).toLocaleTimeString(['es-ES'], {
      timeStyle: 'short'
    });
  }

  /**
   *
   * @param date Date
   * @returns DateFormated
   * get a formated date dd/mm/YY HH:MM
   */
  getFullDateTime(date) {
    return new Date(date).toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   *
   * @param date Date
   * @returns DateFormated
   * get a formated date dd/mm HH:MM
   */
  getFullDateTimeWithoutYear(date) {
    return new Date(date).toLocaleTimeString([], {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  /**
   *
   * @param startDate Date
   * @param endDate Date
   * @returns DateRangeParams
   * get a formated date range from two diffferent dates
   */
  formatDateRanges(startDate: Date | null, endDate: Date | null): IDateRange {
    if (startDate && endDate) {
      const fromDate = this._formatFromDate(startDate);
      const toDate = this._formatFromDate(endDate);
      return { fromDate, toDate };
    }
    return { fromDate: '', toDate: '' };
  }

  dateFromISO8601(isoDateString) {
    let parts = isoDateString.match(/\d+/g);
    let isoTime = Date.UTC(
      parts[0],
      parts[1] - 1,
      parts[2],
      parts[3],
      parts[4],
      parts[5]
    );
    let isoDate = new Date(isoTime);

    return isoDate;
  }

  /**
   *
   * @param date Date
   * @returns string
   * format a given date to be yyyy-mm-dd
   */

  private _formatFromDate(date) {
    return new Date(date)
      .toLocaleDateString('ja', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/\//g, '-');
  }

  /**
   *
   * @param seconds
   * @returns number of days and hours
   * format a given amount of seconds to be days and hours
   */
  formatSecondsToDaysAndHours(seconds: number) {
    let days = Math.floor(seconds / (24 * 3600));
    let hours = Math.floor((seconds - days * 24 * 3600) / 3600).toString();
    return `${days}d ${hours.padStart(2, '0')}h`;
  }

  formatSecondsToHours(seconds: any, numberOfDecimals: number = 0) {
    if (seconds === null || seconds === undefined) {
      return '-';
    }
    let hours = Math.floor(seconds / 3600).toString();
    if (numberOfDecimals > 0) {
      hours = (seconds / 3600)
        .toFixed(numberOfDecimals)
        .toString()
        .replace('.', ',');
    }
    return `${hours.padStart(2, '0')}h`;
  }

  formatSecondsToMinutes(seconds: any, numberOfDecimals: number = 0) {
    if (seconds === null || seconds === undefined) {
      return '-';
    }
    let minutes = Math.floor(seconds / 60).toString();
    if (numberOfDecimals > 0) {
      minutes = (seconds / 60).toFixed(numberOfDecimals).toString();
    }
    return `${minutes.padStart(2)} min`;
  }

  formatTimeToHoursAndMinutes(timeMinutes: number) {
    if (timeMinutes === 0) {
      return `${timeMinutes}h`;
    }
    let hours = Math.floor(Math.abs(timeMinutes) / 60);
    let minutes = Math.abs(timeMinutes) % 60;
    return `${timeMinutes < 0 ? '-' : ''}${hours}h ${minutes}m`;
  }

  /**
   *
   * @returns Current date formatted as YYYYMMDD
   */
  formatCompleteISODate() {
    return new Date()
      .toLocaleDateString('ja', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/\//g, '');
  }

  /**
   *
   * @param utcDate
   * @param hours Number of hours to sum, by default +1
   */
  addHoursToUtcDate = (utcDate: string, hours: number = 1) => {
    const newDate = new Date(utcDate);
    newDate.setHours(newDate.getHours() + hours);
    return newDate.toISOString().slice(0, 19) + 'Z';
  };

  formatTimeToFullDate = (date: Date | null): string => {
    if (date === null) {
      return '';
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${month}.${day}.${year}, ${hour}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };
}

export default new GetDateFormatedService({ currentDate: new Date() });

export { GetDateFormatedService };
