import IRefillsRepository from '../domain/IRefillsRepository';
import RefillsAlertsData from '../domain/RefillsAlertsData';
import refillsRepository from '../infrastructure/repositories/RefillsRepository';

class GetRefillsAlertsService {
  private readonly _refillsRepository: IRefillsRepository;

  constructor({ repository }) {
    this._refillsRepository = repository;
  }
  execute() {
    return this._refillsRepository
      .getRefillsAlerts()
      .then((res) => RefillsAlertsData.generateFromDTO(res));
  }
}

export default new GetRefillsAlertsService({
  repository: refillsRepository
});

export { GetRefillsAlertsService };
