import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IDirectDeliveryRepository from '../../domain/IDirectDeliveryRepository';
import DirectDeliveryDataDTO from '../dto/DirectDeliveryDataDTO';
import UpcomingTransitsDTO from '../dto/UpcomingTransitsDTO';
import DirectDeliveryFiltersDataDTO from '../dto/DirectDeliveryFiltersDTO';
import {
  API_DOWNLOAD_UPCOMING_TRANSITS,
  API_GET_DIRECT_DELIVERY,
  API_GET_UPCOMING_TRANSITS,
  API_GET_DIRECT_DELIVERY_FILTERS,
  API_GET_DD_COVERAGE_AT_RISK,
  API_DOWNLOAD_DIRECT_DELIVER,
  API_DOWNLOAD_HISTORY
} from './urls';
import CoverageAtRiskDTO from '../../../../shared/infrastructure/dto/CoverageAtRiskDTO';

class DirectDeliveryRepository
  extends BaseRepository
  implements IDirectDeliveryRepository
{
  private readonly _api: IApi;
  private readonly _apiGetDirectDelivery: string;
  private readonly _apiGetUpcomingTransits: string;
  private readonly _apiGetDownloadUpcomingTransits: string;
  private readonly _apiGetDirectDeliveryFilters: string;
  private readonly _apiGetDirectDeliveryCoverageAtRisk: string;
  private readonly _apiGetDownloadDirectDelivery: string;
  private readonly _apiGetDonwloadHistory: string;

  constructor({
    api,
    apiGetDirectDelivery,
    apiGetUpcomingTransits,
    apiGetDownloadUpcomingTransits,
    apiGetDirectDeliveryFilters,
    apiGetDirectDeliveryCoverageAtRisk,
    apiGetDownloadDirectDelivery,
    apiGetDonwloadHistory
  }) {
    super();
    this._api = api;
    this._apiGetDirectDelivery = apiGetDirectDelivery;
    this._apiGetUpcomingTransits = apiGetUpcomingTransits;
    this._apiGetDownloadUpcomingTransits = apiGetDownloadUpcomingTransits;
    this._apiGetDirectDeliveryFilters = apiGetDirectDeliveryFilters;
    this._apiGetDirectDeliveryCoverageAtRisk =
      apiGetDirectDeliveryCoverageAtRisk;
    this._apiGetDownloadDirectDelivery = apiGetDownloadDirectDelivery;
    this._apiGetDonwloadHistory = apiGetDonwloadHistory;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetDirectDelivery}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new DirectDeliveryDataDTO(res));
  }

  getUpcomingTransits({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetUpcomingTransits}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new UpcomingTransitsDTO(res));
  }

  downloadUpcomingTransits({ queryParams }) {
    const url = `${
      this._apiGetDownloadUpcomingTransits
    }${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  downloadDirectDelivery({ queryParams }) {
    const url = `${this._apiGetDownloadDirectDelivery}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  getDirectDeliveryFilters() {
    return this._api
      .get(this._apiGetDirectDeliveryFilters)
      .then((res) => new DirectDeliveryFiltersDataDTO(res));
  }

  getCoverageAtRisk({ queryParams }: { queryParams: object }) {
    const url = `${
      this._apiGetDirectDeliveryCoverageAtRisk
    }${this._createQueryParams(queryParams)}`;

    return this._api.get(url).then((res) => new CoverageAtRiskDTO(res));
  }

  downloadHistory({ queryParams }) {
    const url = `${this._apiGetDonwloadHistory}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new DirectDeliveryRepository({
  api: api,
  apiGetDirectDelivery: API_GET_DIRECT_DELIVERY,
  apiGetUpcomingTransits: API_GET_UPCOMING_TRANSITS,
  apiGetDownloadUpcomingTransits: API_DOWNLOAD_UPCOMING_TRANSITS,
  apiGetDirectDeliveryFilters: API_GET_DIRECT_DELIVERY_FILTERS,
  apiGetDirectDeliveryCoverageAtRisk: API_GET_DD_COVERAGE_AT_RISK,
  apiGetDownloadDirectDelivery: API_DOWNLOAD_DIRECT_DELIVER,
  apiGetDonwloadHistory: API_DOWNLOAD_HISTORY
});

export { DirectDeliveryRepository };
