import fulfillmentRepository from 'modules/outbound/fulfillment/infrastructure/repositories/FulfillmentRepository';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import TransitFulfillmentCheckpoint from 'modules/outbound/fulfillment/domain/TransitFulfillmentCheckpoint';

class TransitFulfillmentCheckpointsByCountryService {
  private readonly _fulfillmentRepository: ITransitRepository;

  constructor({ fulfillmentRepository }) {
    this._fulfillmentRepository = fulfillmentRepository;
  }

  execute({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    return this._fulfillmentRepository.getCheckpointsByCountry!({
      queryParams,
      countryCode
    }).then((res) => ({
      countryCode,
      checkpoints: res.map((element) =>
        TransitFulfillmentCheckpoint.generateFromDTO(element)
      )
    }));
  }
}

export default new TransitFulfillmentCheckpointsByCountryService({
  fulfillmentRepository: fulfillmentRepository
});

export { TransitFulfillmentCheckpointsByCountryService };
