import { ILastUpdateRepository } from '../../domain/LastUpdate/ILastUpdateRepository';
import { LastUpdateType } from '../../domain/LastUpdate/types';
import { lastUpdateRepository } from '../../infraestructure/repositories/LastUpdateRepository';

class GetLastUpdateUseCase {
  private readonly _repository: ILastUpdateRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({ queryParams }: { queryParams: { key: LastUpdateType } }) {
    return this._repository.getLastUpdate({ queryParams });
  }
}

const getLastUpdateUseCase = new GetLastUpdateUseCase({
  repository: lastUpdateRepository
});

export { GetLastUpdateUseCase, getLastUpdateUseCase };
