import TransferStockNextHours from 'modules/inbound/transfer/domain/TransferStockNextHours';
import ITransferRepository from '../domain/ITransferRepository';
import transferRepository from '../infrastructure/repositories/TransferRepository';

class GetTransferStockNextHoursUseCase {
  private readonly _repository: ITransferRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<TransferStockNextHours> {
    return this._repository
      .getTransferNextHoursStock({ queryParams })
      .then((res) => TransferStockNextHours.generateFromDTO(res));
  }
}

export default new GetTransferStockNextHoursUseCase({
  repository: transferRepository
});

export { GetTransferStockNextHoursUseCase };
