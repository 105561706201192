import { stockRepository } from 'modules/outbound/shared/infrastructure/repositories/StockRepository';
import IStockRepository from 'modules/outbound/shared/domain/IStockRepository';
import StockAging from 'modules/outbound/shared/domain/StockAging';

class GetStockAgingUseCase {
  private readonly _repository: IStockRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<{
    total: StockAging;
    blocked: StockAging;
    notBlocked: StockAging;
  }> {
    return this._repository.getStockAging().then((response) => ({
      total: StockAging.generateFromDto(response.total),
      blocked: StockAging.generateFromDto(response.blocked),
      notBlocked: StockAging.generateFromDto(response.notBlocked)
    }));
  }
}

export const getStockAgingUseCase = new GetStockAgingUseCase({
  repository: stockRepository
});

export { GetStockAgingUseCase };
