import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import ICommercialPackagesRepository from 'modules/outbound/shared/domain/ICommercialPackagesRepository';
import { API_GET_COMMERCIAL_PACKAGES } from './urls';

class CommercialPackagesRepository
  extends BaseRepository
  implements ICommercialPackagesRepository
{
  private readonly _api: IApi;
  private readonly _apiGetCommercialPackages: string;

  constructor({ api, apiGetCommercialPackages }) {
    super();
    this._api = api;
    this._apiGetCommercialPackages = apiGetCommercialPackages;
  }

  getCommercialPackages() {
    return this._api.get(this._apiGetCommercialPackages);
  }
}

export default new CommercialPackagesRepository({
  api: api,
  apiGetCommercialPackages: API_GET_COMMERCIAL_PACKAGES
});

export { CommercialPackagesRepository };
