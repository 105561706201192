import PipelineDetailedCarInfo from 'modules/outbound/stock/domain/PipelineDetailedCarInfo';
import IPipelineStockRepository, {
  IGetParams
} from 'modules/outbound/stock/domain/IPipelineStockRepository';
import pipelineStockRepository from 'modules/outbound/stock/infrastructure/repositories/PipelineStockRepository';
import PipelineDetailedCarInfoDTO from 'modules/outbound/stock/infrastructure/dto/PipelineDetailedCarInfoDTO';

class GetPipelineDetailedCarInfoService {
  private readonly _pipelineStockRepository: IPipelineStockRepository;

  constructor({ repository }) {
    this._pipelineStockRepository = repository;
  }

  get({ reference, queryParams }: { reference: string; queryParams: object }) {
    const params = this._getParamsFromReference(reference);
    const completeQueryParams = this._addQueryParams({
      queryParams,
      extraParams: params.queryParams
    });
    return this._pipelineStockRepository
      .getDetailedInfo({
        reference: params.reference,
        queryParams: completeQueryParams
      })
      .then((res) => this._mapToPipelineDetailedCarInfo(res))
      .then((res) => ({
        detailedCarInfo: res,
        reference
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToPipelineDetailedCarInfo(
    dto: PipelineDetailedCarInfoDTO
  ): PipelineDetailedCarInfo {
    return PipelineDetailedCarInfo.generateFromDTO(dto);
  }

  private _getParamsFromReference(reference: string): IGetParams {
    const references = reference.split('-');
    const brand = references[0];
    const model = references[1];
    if (model === 'All') {
      if (brand === 'All') {
        return { reference: 'All' };
      }
      return { reference: 'All', queryParams: { brands: brand } };
    }
    return { reference: model };
  }

  private _addQueryParams({ queryParams, extraParams }) {
    return { ...queryParams, ...extraParams };
  }
}

export default new GetPipelineDetailedCarInfoService({
  repository: pipelineStockRepository
});

export { GetPipelineDetailedCarInfoService };
