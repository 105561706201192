import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class DownloadEntriesUseCase {
  private readonly _entriesRepository: IEntriesRepository;

  constructor({ entriesRepository }) {
    this._entriesRepository = entriesRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._entriesRepository.downloadEntries({
      queryParams
    });
  }
}

export default new DownloadEntriesUseCase({
  entriesRepository: entriesRepository
});

export { DownloadEntriesUseCase };
