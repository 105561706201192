import IMessagesRepository from '../../domain/InboundMessages/IMessagesRepository';
import MessagesRepository from '../../infraestructure/repositories/MessagesRepository';

class DeleteInboundMessageUseCase {
  private readonly _inboundMessagesRepository: IMessagesRepository;

  constructor({ repository }) {
    this._inboundMessagesRepository = repository;
  }

  execute(uid: string) {
    return this._inboundMessagesRepository
      .deleteMessage(uid)
      .then((res) => res);
  }
}

export { DeleteInboundMessageUseCase };
export default new DeleteInboundMessageUseCase({
  repository: MessagesRepository
});
