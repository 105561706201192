import FinishedGoods from 'modules/outbound/fulfillment/domain/FinishedGoods';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';
import { IFinishedGoodDTO } from '../infrastructure/dto/FinishedGoodDTO';

class FinishedGoodService {
  private readonly _deliveriesGeolocationRepository: ITransitRepository;

  constructor({ deliveriesGeolocationRepository }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
  }

  get({ queryParams }: { queryParams: object }) {
    return this._deliveriesGeolocationRepository.getFinishedGood!({
      queryParams
    }).then((res) => ({
      countryCode: res.destinationCountry,
      checkpointRef: res.currentCheckpoint,
      finishedGoods: this._mapToFinishedGoods(res.finishedGood)
    }));
  }

  private _mapToFinishedGoods(dto: IFinishedGoodDTO): FinishedGoods[] {
    return [FinishedGoods.generateFromDTO(dto)];
  }
}

export default new FinishedGoodService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository
});

export { FinishedGoodService };
