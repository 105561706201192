import { INoJitRepository } from '../domain/INoJitRepository';
import nojitRepository from '../infrastructure/repositories/NoJitRepository';

class EditCommentUseCase {
  private readonly nojitRepository: INoJitRepository;

  constructor({ repository }) {
    this.nojitRepository = repository;
  }

  execute({
    code,
    body
  }: {
    code: string;
    body: {
      text: string;
    };
  }) {
    return this.nojitRepository
      .updateComment({ code, body })
      .then((res) => res);
  }
}

export { EditCommentUseCase };
export default new EditCommentUseCase({
  repository: nojitRepository
});
