import { Translation } from 'infrastructure/i18n/typing';
import { NoJitTranslations } from './typing';

export const NoJitText: Translation<NoJitTranslations> = {
  es: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage: 'No hay stock disponible para el proveedor.',
    stockUpdate: 'Act. stock:',
    productionPlanUpdate: 'Act. plan producción:',
    code: 'Clave',
    providerCode: 'Clave proveedor',
    name: 'Descripción',
    lines: 'Lineas',
    models: 'Modelos',
    stock: 'Stock en almacén',
    factoryCoverage: 'Stock',
    tierStock: 'Stock T1',
    stockReportedBySupplier: 'Stock reportado por el proveedor',
    endProductionInDays: 'Cese',
    inbounder: 'Inbounder',
    providerName: 'Proveedores',
    providerId: 'Código proveedor',
    country: 'País',
    workshops: 'Talleres',
    countriesMultiselectAvailable: 'Paises disponibles',
    countriesMultiselectSelected: 'Paises seleccionados',
    countriesMultiselectEmpty: 'No hay paises disponibles',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    suppliersMultiselectAvailable: 'Proveedores disponibles',
    suppliersMultiselectSelected: 'Proveedores seleccionados',
    suppliersMultiselectEmpty: 'No hay proveedores disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionados',
    inbounderMultiselectEmpty: 'No hay inbounders disponibles',
    modelMultiselectAvailable: 'Modelos disponibles',
    modelMultiselectSelected: 'Modelos seleccionados',
    modelMultiselectEmpty: 'No hay modelos disponibles',
    workshopMultiselectAvailable: 'Talleres disponibles',
    workshopMultiselectSelected: 'Talleres seleccionados',
    workshopMultiselectEmpty: 'No hay talleres disponibles',
    warehousesMultiselectAvailable: 'Almacenes disponibles',
    warehousesMultiselectSelected: 'Almacenes seleccionados',
    warehousesMultiselectEmpty: 'No hay Almacenes disponibles',
    geolocated: 'Geolocalizado',
    mrp: 'Pedido',
    mrps: 'Pedidos',
    more: '...más',
    pastLoads: 'Info soporte de tránsitos',
    pastLoadsTooltip:
      'Datos de ASN inesperados: <ul><li>Agrupación de transportes con fecha prevista de ASN en el pasado.</li> <li>Agrupación de transportes sin confirmación de llegada.</li></ul>',
    coverageAtRisk: 'Materiales en riesgo',
    coverageAtRiskOK: 'Cobertura OK',
    coverageAtRiskSecurity: 'Riesgo stock de seguridad',
    coverageAtRiskCritical: 'Cobertura crítica',
    searcherPartOrNamePlaceholder: 'Búsqueda por clave o descripción',
    plateCopiedText: 'Número de matrícula copiado',
    plateEmptyText: 'No existe número de matrícula para copiar',
    calculationTooltip:
      'El cálculo considera hasta 15 días, todo lo demás se refleja como MAX',
    coverageDateTimeTooltip:
      'Fecha y hora cuando el stock de SEAT no llega a cubrir consumos. Actualmente no cuenta el stock en línea',
    warehouse: 'Almacén',
    viewTransportsAndOrders: 'Visualiza los transportes y pedidos existentes',
    type: 'Tipo',
    amount: 'Cantidad',
    providerDeliveryNote: 'Albarán',
    plateNumber: 'Matrícula tractora',
    transporter: 'Transportista',
    leadtime: 'Leadtime',
    transit: 'Tránsito',
    transits: 'Tránsitos',
    transportsNoJit: 'Plan de entradas',
    nonGeolocatedTransport: 'T. No geolocalizado',
    unknow: '-',
    missing: 'Faltantes',
    factoryCoverageTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>stock en almacén</b>:<ul><li>En corto plazo: MAX (dd/mm, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    coverageWithLabel: 'Cobertura con',
    coverageOrdersTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>SEAT stock + Programas (planes de entrega)</b>:<ul><li>En corto plazo: MAX (dd/mm, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    orders: 'Pedidos',
    viewOrders: 'Visualiza los pedidos existentes',
    urgentTransit: 'Tránsito urgente',
    noOrders: 'Sin pedidos',
    transitsCoverage: 'Tránsitos',
    transitsCoverageTooltip:
      'Hasta que día y hora se <b>cubre</b> la producción con <b>SEAT stock + Tránsitos en curso</b>: <ul><li>En corto plazo: MAX (dd/mm, hh:mm) = producción cubierta > 15 días</li><li>En largo plazo: MAX = producción cubierta > 75 días</li></ul>',
    deliveryOrders: 'Entrega pedidos',
    windowBooked: 'Ventana horaria',
    estimatedTime: 'Estimada modelo',
    estimatedTimeTitle: 'Fecha estimada',
    supplierDate: 'Fecha proveedor',
    provDeliveryTimeTooltip:
      'Fecha estimada de llegada según ASN del proveedor',
    leadtimeExpectedDate: 'Fecha lead time',
    providerExpectedDate: 'Proveedor',
    provDeliveryTime: 'Fecha proveedor',
    statusTooltip:
      '<b>Estado tránsitos:</b><ul><li><b>Atrasado</b>: Tiene fecha prevista a pasado</li><li><b>En consolidación</b>: Tiene el estado TP</li><li><b>En expedición</b>: Tiene el estado TR</li><li><b>Descargando planta</b>: Tiene el estado EP</li></ul>',
    departureDate: 'Fecha salida',
    departureDateTooltip: 'Fecha salida según ASN del proveedor',
    kccTransit: 'Tránsito consolidación',
    leadtimeTooltip: 'Tiempo pactado de expedición con cada proveedor',
    officialDeliveryTime: 'Fecha oficial sist.',
    officialDeliveryTimeTooltip:
      '<b>En los tránsitos:</b><ul><li>Muestra la fecha de llegada más precisa del sistema.</li></ul> <i>(La fecha oficial se usa para el cálculo de cobertura con tránsitos y ubica el día de llegada del transporte en el calendario. La fecha es dinámica y cambia de tipo durante el tiempo.)</i><br /><br/><b>En los pedidos:</b><ul><li>Es la fecha de planificada del pedido.</li></ul>',
    transitOfficialDeliveryTimeTooltip:
      'Muestra la fecha de llegada más precisa que tiene el sistema. Es la misma fecha que la mostrada en la columna de días.',
    mrpOfficialDeliveryTimeTooltip:
      'Fecha acordada en la que el pedido debe llegar a SEAT.',
    dateType: 'Tipo fecha oficial',
    dateTypeTooltip:
      'Tipos de fechas en tránsitos (por orden de precisión sobre disponibilidad):<ul><li><b> Ventana horaria:</b> Fecha reservada desde Martorell para la entrada a planta del transporte (LKW)</li><li><b> Estimada modelo:</b> Fecha calculada mediante un modelo predictivo basandose en históricos de llegada</li><li><b> Fecha proveedor:</b> Fecha notificada por parte del proveedor a fábrica mediante ASN</li><li><b> Fecha lead time:</b> Fecha de salida del transporte + lead time contratado</li></ul>',
    tierStockUpdatedAt: 'Fecha act. stock T1',
    tierStockUpdatedAtTooltip: 'Fecha de actualización del stock del Tier 1',
    coverages: 'Coberturas',
    withStock: 'Con stock',
    withOrders: 'Con pedidos',
    coverageLessZero: 'Cobertura < 0',
    withTransits: 'Con tránsito',
    withoutNeeds: 'Sin necesidades',
    shortTerm: 'Horizonte corto plazo',
    longTerm: 'Horizonte largo plazo',
    shortTermExtended: 'Corto plazo (próximos 15 días)',
    longTermExtended: 'Largo plazo (próximas 11/12 semanas)',
    multiple: 'Múltiples',
    entryTypes: 'Tipos de entrada',
    geolocationEta: 'ETA geolocalizada',
    geolocation: 'Geolocalización',
    dateTypeModalTransport: 'Tipo fecha',
    firm: 'Firme',
    delay: 'Atraso',
    forecast: 'Previsión',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Coberturas en riesgo',
    comments: 'Comentarios',
    editComment: 'Clica en el icono para editar el comentario',
    removeComment: 'Clica en el icono para borrar el comentario',
    updateComment: 'Clica en el icono para actualizar el comentario',
    commentsTooltip:
      'Los comentarios son solamente visibles para los usuarios de aprovisionamiento, los proveedores no ven esta información',
    commentUpdatedSuccess: 'El comentario se ha actualizado con éxito',
    commentRemovedSuccess: 'El comentario se ha eliminado correctamente',
    commentUpdatedFailed:
      'El comentario no se ha podido actualizar, pruebe pasados unos minutos.',
    noEntries: 'Sin entradas'
  },
  en: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage: 'There is no available stock yet for this provider.',
    stockUpdate: 'Stock upd.:',
    productionPlanUpdate: 'Act. production plan:',
    code: 'Parts',
    providerCode: 'Provider part',
    name: 'Description',
    lines: 'Lines',
    models: 'Models',
    stock: 'Stock in warehouse',
    factoryCoverage: 'Stock',
    tierStock: 'T1 Stock',
    stockReportedBySupplier: 'Stock reported by supplier',
    endProductionInDays: 'EOP',
    inbounder: 'Inbounder',
    providerName: 'Suppliers',
    providerId: 'Supplier code',
    country: 'Country',
    workshops: 'Workshops',
    countriesMultiselectAvailable: 'Countries available',
    countriesMultiselectSelected: 'Countries selected',
    countriesMultiselectEmpty: "There aren't countries available",
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    suppliersMultiselectAvailable: 'Suppliers available',
    suppliersMultiselectSelected: 'Suppliers selected',
    suppliersMultiselectEmpty: "There aren't suppliers available",
    inbounderMultiselectAvailable: 'Inbounders available',
    inbounderMultiselectSelected: 'Inbounders selected',
    inbounderMultiselectEmpty: "There aren't inbounders available",
    modelMultiselectAvailable: 'Models available',
    modelMultiselectSelected: 'Models selected',
    modelMultiselectEmpty: "There aren't models available",
    workshopMultiselectAvailable: 'Workshops availables',
    workshopMultiselectSelected: 'Workshops selected',
    workshopMultiselectEmpty: "There aren't workshops available",
    warehousesMultiselectAvailable: 'Warehouses available',
    warehousesMultiselectSelected: 'Warehouses selected',
    warehousesMultiselectEmpty: "There aren't Warehouses",
    geolocated: 'Geolocated',
    mrp: 'Order',
    mrps: 'Orders',
    more: '...more',
    pastLoads: 'Transit support info',
    pastLoadsTooltip:
      'Unexpected ASN data: <ul><li>Grouping of transports with expected date of ASN in the past.</li><li>Transport grouping without confirmation of arrival.</li></ul>',
    coverageAtRisk: 'Materials at risk',
    coverageAtRiskOK: 'Coverage OK',
    coverageAtRiskSecurity: 'Security stock risk',
    coverageAtRiskCritical: 'Critical coverage',
    searcherPartOrNamePlaceholder: 'Search by part or description',
    plateCopiedText: 'Plate number copied',
    plateEmptyText: 'There is no plate number to copy',
    calculationTooltip:
      'Calculation considers up to 15 days, anything further is reflected as MAX',
    coverageDateTimeTooltip:
      "Date and time when the SEAT stock doesn't cover consumption. Currently doesn't count inline stock",
    warehouse: 'Warehouse',
    viewTransportsAndOrders: 'View existing transports and orders',
    type: 'Type',
    amount: 'Quantity',
    providerDeliveryNote: 'Delivery note',
    plateNumber: 'Tractor plate number',
    transporter: 'Transporter',
    leadtime: 'Leadtime',
    transit: 'Transit',
    transits: 'Transits',
    transportsNoJit: 'Entries plan',
    nonGeolocatedTransport: 'Non-geolocated transit',
    unknow: '-',
    missing: 'Missing',
    factoryCoverageTooltip:
      'Until what day and time is production <b>covered</b> with <b>warehouse stock</b>: <ul><li>In the short term: MAX (dd/mm, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    coverageWithLabel: 'Coverage with',
    coverageOrdersTooltip:
      'Until what day and time is production <b>covered</b> with <b>SEAT stock + Programs (delivery plans)</b>: <ul><li>In the short term: MAX (dd/mm, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    orders: 'Orders',
    viewOrders: 'View existing orders',
    urgentTransit: 'Urgent transit',
    noOrders: 'No orders',
    transitsCoverage: 'Transits',
    transitsCoverageTooltip:
      'Until what day and time is production <b>covered</b> with <b>SEAT stock + Transits in progress</b>:<ul><li>In the short term: MAX (dd/mm, hh:mm) = covered production > 15 days</li><li>In the long term: MAX = covered production > 75 days</li></ul>',
    deliveryOrders: 'Delivery orders',
    windowBooked: 'Windows booked',
    estimatedTimeTitle: 'Estimated date: ',
    estimatedTime: 'Model estimation',
    supplierDate: 'Supplier date',
    provDeliveryTimeTooltip:
      "Estimated arrival date according to supplier's ASN",
    leadtimeExpectedDate: 'Lead time date',
    providerExpectedDate: 'Supplier',
    provDeliveryTime: 'Supplier date',
    statusTooltip:
      '<b>Transit status:</b><ul><li><b>Delayed</b>: It has a scheduled date that has passed</li><li><b>In consolidation</b>: It has the status TP</li><li><b>In shipment</b>: It has the status TR</li><li><b>Unloading plant</b>: It has the status EP</li></ul>',
    departureDate: 'Departure date',
    departureDateTooltip: "Departure date according to supplier's ASN",
    kccTransit: 'Transit consolidation',
    leadtimeTooltip: 'Agreed delivery time with each supplier',
    officialDeliveryTime: 'Official date syst.',
    officialDeliveryTimeTooltip:
      '<b>In transits:</b><ul><li>Shows the most precise arrival date in the system.</li></ul><i>(The official date is used to calculate coverage with transits and locates the transportation arrival day on the calendar. The date is dynamic and changes type over time.)</i><br/><br/><b>In orders:</b> <ul><li>It is the planned date of the order.</li></ul>',
    transitOfficialDeliveryTimeTooltip:
      'Displays the most accurate arrival date in the system. It is the same date shown in the days column.',
    mrpOfficialDeliveryTimeTooltip:
      'Agreed date on which the order must arrive at SEAT.',
    dateType: 'Official date type',
    dateTypeTooltip:
      'Types of dates in transit (in order of precision on availability):<ul><li><b>Time window:</b> Date reserved from Martorell for entry to the transport plant (LKW)</li><li><b>Estimated model:</b> Date calculated using a predictive model based on arrival history</li><li><b>Supplier date:</b> Date notified by the supplier to the factory through ASN</li><li><b>Lead time date:</b> Transport departure date + contracted lead time</li></ul>',
    geolocation: 'Geolocation',
    tierStockUpdatedAt: 'Date upd. T1 stock',
    tierStockUpdatedAtTooltip: 'Tier 1 stock update date',
    coverages: 'Coverages',
    withStock: 'With stock',
    withOrders: 'With orders',
    coverageLessZero: 'Coverage < 0',
    withTransits: 'With transit',
    withoutNeeds: 'Without needs',
    shortTerm: 'Short term horizon',
    longTerm: 'Long term horizon',
    shortTermExtended: 'Short term (next 15 days)',
    longTermExtended: 'Long term (next 11/12 weeks)',
    multiple: 'Multiple',
    entryTypes: 'Entry types',
    geolocationEta: 'Geolocated ETA',
    dateTypeModalTransport: 'Date type',
    firm: 'Firm',
    delay: 'Delay',
    forecast: 'Forecast',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Coverage at risk',
    comments: 'Comments',
    editComment: 'Click on the icon to edit the comment',
    removeComment: 'Click on the icon to remove the comment',
    updateComment: 'Click on the icon to update the comment',
    commentsTooltip:
      'Comments are only visible to provisioning users, suppliers do not see this information',
    commentUpdatedSuccess: 'Comment has been updated successfully',
    commentRemovedSuccess: 'Comment has been successfully deleted',
    commentUpdatedFailed:
      'The comment could not be updated, try after a few minutes.',
    noEntries: 'No entries'
  },
  ca: {
    pageTitle: 'NOJIT Supply Coverage',
    menuTitle: 'NOJIT',
    emptyMessage:
      'Encara no hi ha existències disponibles per a aquest proveïdor.',
    stockUpdate: 'Act. estoc:',
    productionPlanUpdate: 'Act. pla producció:',
    code: 'Clau',
    providerCode: 'Clau proveïdor',
    name: 'Descripció',
    lines: 'Línies',
    models: 'Models',
    stock: 'Estoc al magatzem',
    factoryCoverage: 'Stock',
    tierStock: 'Stock T1',
    stockReportedBySupplier: 'Estoc reportat pel proveïdor',
    endProductionInDays: 'Cessament',
    inbounder: 'Inbounder',
    providerName: 'Proveïdors',
    providerId: 'Codi Proveïdor',
    country: 'País',
    workshops: 'Tallers',
    countriesMultiselectAvailable: 'Països disponibles',
    countriesMultiselectSelected: 'Països seleccionats',
    countriesMultiselectEmpty: 'No hi ha països disponibles',
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    suppliersMultiselectAvailable: 'Proveïdors disponibles',
    suppliersMultiselectSelected: 'Proveïdors seleccionats',
    suppliersMultiselectEmpty: 'No hi ha proveïdors disponibles',
    inbounderMultiselectAvailable: 'Inbounders disponibles',
    inbounderMultiselectSelected: 'Inbounders seleccionats',
    inbounderMultiselectEmpty: 'No hi ha inbounders disponibles',
    modelMultiselectAvailable: 'Models disponibles',
    modelMultiselectSelected: 'Models seleccionats',
    modelMultiselectEmpty: 'No hi ha models disponibles',
    workshopMultiselectAvailable: 'Tallers disponibles',
    workshopMultiselectSelected: 'Tallers seleccionats',
    workshopMultiselectEmpty: 'No hi ha tallers disponibles',
    warehousesMultiselectAvailable: 'Magatzems disponibles',
    warehousesMultiselectSelected: 'Magatzems seleccionats',
    warehousesMultiselectEmpty: 'No hi ha Magatzems disponibles',
    geolocated: 'Geolocalitzat',
    mrp: 'Comanda',
    mrps: 'Comandes',
    more: '...més',
    pastLoads: 'Info suport de trànsits',
    pastLoadsTooltip:
      "Dades d'ASN inesperades: <ul><li>Agrupació de transports amb data prevista d'ASN al passat.</li><li>Agrupació de transports sense confirmació d'arribada.</li></ul>",
    coverageAtRisk: 'Materials en risc',
    coverageAtRiskOK: 'Cobertura OK',
    coverageAtRiskSecurity: 'Risc estoc de seguretat',
    coverageAtRiskCritical: 'Cobertura crítica',
    searcherPartOrNamePlaceholder: 'Cerca per clau o descripció',
    plateCopiedText: 'Número de matricula copiat',
    plateEmptyText: 'No hi ha número de matrícula per copiar',
    calculationTooltip:
      'El càlcul té en compte fins a 15 dies, qualsevol altra cosa es reflecteix com a MAX.',
    coverageDateTimeTooltip:
      "Data i hora quan l'estoc de SEAT no arriba a cobrir consums. Actualment no compta l'estoc en línia.",
    warehouse: 'Magatzem',
    viewTransportsAndOrders:
      'Visualitza els transports i les comandes existents',
    type: 'Tipus',
    amount: 'Quantitat',
    providerDeliveryNote: 'Albarà',
    plateNumber: 'Matrícula tractora',
    transporter: 'Transportista',
    leadtime: 'Leadtime',
    transit: 'Trànsit',
    transits: 'Trànsits',
    transportsNoJit: "Pla d'entrades",
    nonGeolocatedTransport: 'T. No geolocalitzat',
    unknow: '-',
    missing: 'Faltants',
    factoryCoverageTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>stock del magatzem</b>: <ul><li>A curt termini: MAX (dd/mm, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    coverageWithLabel: 'Cobertura amb',
    coverageOrdersTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>SEAT stock + Programes (plans de lliurament)</b>: <ul><li> A curt termini: MAX (dd/mm, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    orders: 'Comandes',
    viewOrders: 'Visualitza les comandes existents',
    urgentTransit: 'Transit urgent',
    noOrders: 'Sense comandes',
    transitsCoverage: 'Trànsits',
    transitsCoverageTooltip:
      'Fins quin dia i hora es <b>cobreix</b> la producció amb <b>SEAT stock + Trànsits en curs</b>: <ul><li> A curt termini: MAX (dd/mm, hh:mm) = producció coberta > 15 dies</li><li>A llarg termini: MAX = producció coberta > 75 dies</li></ul>',
    deliveryOrders: 'Lliurament comandes',
    windowBooked: 'Finestra horària',
    estimatedTimeTitle: 'Data estimada',
    estimatedTime: 'Estimada model',
    supplierDate: 'Data proveïdor',
    provDeliveryTimeTooltip:
      "Data estimada d'arribada segons ASN del proveïdor",
    leadtimeExpectedDate: 'Data lead time',
    providerExpectedDate: 'Proveïdor',
    provDeliveryTime: 'Data proveïdor',
    statusTooltip:
      "<b>Estat trànsits:</b><ul><li><b>Endarrerit</b>: Té data prevista a passat</li><li><b>En consolidació</b>: Té l'estat TP</li><li><b>En expedició</b>: Té l'estat TR</li><li><b>Descarregant planta</b>: Té l'estat EP</li></ul>",
    departureDate: 'Data sortida',
    departureDateTooltip: 'Data sortida segons ASN del proveïdor',
    kccTransit: 'Transit consolidació',
    leadtimeTooltip: 'Temps pactat dexpedició amb cada proveïdor',
    officialDeliveryTime: 'Data oficial sist.',
    officialDeliveryTimeTooltip:
      "<b>Als trànsits:</b><ul><li>Mostra la data d'arribada més precisa del sistema.</li></ul><i>(La data oficial s'usa per al càlcul de cobertura amb trànsits i ubica el dia d'arribada del transport al calendari. La data és dinàmica i canvia de tipus durant el temps.)</i><br/><br/><b>Per a les comandes:</b> <ul><li>És la data de planificada de la comanda.</li></ul>",
    transitOfficialDeliveryTimeTooltip:
      "Mostra la data d'arribada més precisa que té el sistema. És la mateixa data que es mostra a la columna de dies.",
    mrpOfficialDeliveryTimeTooltip:
      "Data acordada en què la comanda ha d'arribar a SEAT.",
    dateType: 'Tipus data oficial',
    dateTypeTooltip:
      "Tipus de dates en trànsits (per ordre de precisió sobre disponibilitat):<ul><li><b>Finestra horària:</b> Data reservada des de Martorell per a l'entrada a la planta del transport (LKW)</li><li><b>Estimada model:</b> Data calculada mitjançant un model predictiu basant-se en històrics d'arribada</li><li><b>Data proveïdor:</b> Data notificada per part del proveïdor a fàbrica mitjançant ASN</li><li><b>Data lead time:</b> Data de sortida del transport + lead time contractat</li></ul>",
    geolocation: 'Geolocalització',
    tierStockUpdatedAt: 'Data act. estoc T1',
    tierStockUpdatedAtTooltip: "Data d'actualització de l'estoc del Tier 1",
    coverages: 'Cobertures',
    withStock: 'Amb stock',
    withOrders: 'Amb comandes',
    coverageLessZero: 'Cobertura <b 0',
    withTransits: 'Amb trànsit',
    withoutNeeds: 'Sense necessitats',
    shortTerm: 'Horitzó curt termini',
    longTerm: 'Horitzó llarg termini',
    shortTermExtended: 'Curt termini (pròxims 15 dies)',
    longTermExtended: 'Llarg termini (pròximes 11/12 setmanes)',
    multiple: 'Múltiples',
    entryTypes: "Tipus d'entrada",
    geolocationEta: 'ETA geolocalitzada',
    dateTypeModalTransport: 'Tipus data',
    firm: 'Ferm',
    delay: 'Endarreriment',
    forecast: 'Previsió',
    unitDay: 'd',
    coverageAndOrdersMinimizeTitle: 'Cobertures en risc',
    comments: 'Comentaris',
    editComment: 'Clica a la icona per editar el comentari',
    removeComment: 'Clica a la icona per esborrar el comentari',
    updateComment: 'Clica a la icona per actualitzar el comentari',
    commentsTooltip:
      'Els comentaris són només visibles per als usuaris de proveïment, els proveïdors no veuen aquesta informació',
    commentUpdatedSuccess: 'El comentari ha estat actualitzat amb èxit',
    commentRemovedSuccess: "El comentari s'ha eliminat correctament",
    commentUpdatedFailed:
      "El comentari no s'ha pogut actualitzar, proveu passats uns minuts.",
    noEntries: 'Sense entrades'
  }
};
