import IEntriesRepository from '../../domain/IEntriesRepository';
import { UnloadingPointPieces } from '../../domain/UnloadingPoints/unloadingPointPiecesMapper';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetPiecesInEntryUnloadingPointUseCase {
  private readonly _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    queryParams,
    transportId
  }: {
    queryParams: object;
    transportId: number;
  }): Promise<UnloadingPointPieces> {
    return this._repository.getPiecesByEntryAndUnloadingPoint({
      queryParams,
      transportId
    });
  }
}

const getPiecesInEntryUnloadingPointUseCase =
  new GetPiecesInEntryUnloadingPointUseCase({
    repository: entriesRepository
  });

export {
  GetPiecesInEntryUnloadingPointUseCase,
  getPiecesInEntryUnloadingPointUseCase
};
