import IJitRepository from '../domain/IJitRepository';
import PartCoverageFilters from 'modules/inbound/jit/domain/PartCoverageFilters';
import jitRepository from '../infrastructure/repositories/JitRepository';

class GetPartCoverageFiltersService {
  private readonly _jitRepository: IJitRepository;

  constructor({ repository }) {
    this._jitRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._jitRepository
      .getPartCoverageFilters({ queryParams })
      .then((res) => {
        return PartCoverageFilters.generateFromDTO(res);
      });
  }
}

export default new GetPartCoverageFiltersService({
  repository: jitRepository
});

export { GetPartCoverageFiltersService };
