import fulfillmentRepository from 'modules/outbound/fulfillment/infrastructure/repositories/FulfillmentRepository';
import TransitFulfillment from 'modules/outbound/fulfillment/domain/TransitFulfillment';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import GlobalFulfillmentValueObject from 'modules/outbound/fulfillment/domain/GlobalFulfillmentValueObject';

class TransitFulfillmentService {
  private readonly _fulfillmentRepository: ITransitRepository;

  constructor({ fulfillmentRepository }) {
    this._fulfillmentRepository = fulfillmentRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._fulfillmentRepository.get({ queryParams }).then((res) => {
      return {
        globalAccomplishedPercentage:
          GlobalFulfillmentValueObject.generateFromDTO({
            globalKapDelayedCars: res.globalKapDelayedCars,
            globalKapTotalCars: res.globalKapTotalCars
          }).globalAccomplishedPercentage,
        mainAchievements: res.kap
          .map((item) => TransitFulfillment.generateFromDTO(item))
          .sort(this._sortAchievementByPercentage),
        otherAchievements: res.others
          .map((item) => TransitFulfillment.generateFromDTO(item))
          .sort(this._sortAchievementByPercentage)
      };
    });
  }

  /**
   *
   * @param firstItem TransitFulfillment
   * @param secondItem TransitFulfillment
   * returns sorted achievements by its percentage. Should be used in a sort array method
   */
  private _sortAchievementByPercentage(
    firstItem: TransitFulfillment,
    secondItem: TransitFulfillment
  ) {
    return firstItem.percentage - secondItem.percentage;
  }
}

export default new TransitFulfillmentService({
  fulfillmentRepository: fulfillmentRepository
});

export { TransitFulfillmentService };
