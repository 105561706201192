import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import DeliveryTimeSlotDTO from 'modules/outbound/deliveryTime/infrastructure/dto/DeliveryTimeSlotDTO';
import DeliveryTimeSlotsRangeDetailsDTO from 'modules/outbound/deliveryTime/infrastructure/dto/DeliveryTimeSlotsRangeDetailsDTO';
import { API_GET_DELIVERY_TIME } from './urls';
import { IDeliveryTimeRepository } from 'modules/outbound/deliveryTime/domain/IDeliveryTimeRepository';

class DeliveryTimeRepository
  extends BaseRepository
  implements IDeliveryTimeRepository
{
  private readonly _api: IApi;
  private readonly _getDeliveryTimeUrl: string;

  constructor({ api, getDeliveryTimeUrl }) {
    super();
    this._api = api;
    this._getDeliveryTimeUrl = getDeliveryTimeUrl;
  }

  getDeliveryTimeSlots({ queryParams }: { queryParams: object }) {
    const url = `${this._getDeliveryTimeUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => res.map((item) => new DeliveryTimeSlotDTO(item)));
  }

  getDeliveryTimeSlotsRangeDetails({ queryParams }: { queryParams: object }) {
    const url = `${this._getDeliveryTimeUrl}/details${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => new DeliveryTimeSlotsRangeDetailsDTO(res));
  }
}

export default new DeliveryTimeRepository({
  api: api,
  getDeliveryTimeUrl: API_GET_DELIVERY_TIME
});

export { DeliveryTimeRepository };
