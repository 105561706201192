import { IMasterRepository } from '../domain/IMastersRepository';
import mastersRepository from '../infrastructure/repositories/MastersRepository';

class GetMastersDataUseCase {
  private readonly _mastersRepository: IMasterRepository;

  constructor({ repository }) {
    this._mastersRepository = repository;
  }

  execute() {
    return this._mastersRepository.getMastersInfo();
  }
}

export { GetMastersDataUseCase };
export default new GetMastersDataUseCase({
  repository: mastersRepository
});
