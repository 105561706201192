import {
  API_GET_REQUESTS_STATUS,
  API_GET_SUPPLIES,
  API_GET_SUPPLIES_FILTERS,
  API_GET_CLOSED_STATUS,
  API_GET_SUPPLIES_CRITICAL_STOCK,
  API_GET_SUPPLIES_CRITICAL_STOCK_SUMMARY,
  API_GET_WAREHOUSES_TARGET,
  API_DOWNLOAD_SUPPLIES,
  API_GET_WAREHOUSES_RANKING,
  API_GET_SUBZONES_RANKING
} from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import SuppliesDataDTO from '../dto/SuppliesDataDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import ISuppliesRepository from '../../domain/ISuppliesRepository';
import SuppliesRequestsStatusDTO from '../dto/SuppliesStatusDTO';
import SuppliesFiltersDataDTO from 'modules/inbound/supplies/infrastructure/dto/SuppliesFiltersDTO';
import SummaryCriticalStockDTO from '../../../shared/infraestructure/dto/SummaryCriticalStockDTO';
import { criticalPiecesSummaryDTOMapper } from '../dto/criticalPiecesSummaryDTOMapper';
import {
  CriticalPiecesSummary,
  criticalPiecesSummaryMapper
} from '../../domain/criticalPiecesSummaryMapper';
import {
  WarehouseRankingDTO,
  warehouseRankingDTOMapper
} from '../dto/warehouseRankingDTOMapper';

class SuppliesRepository extends BaseRepository implements ISuppliesRepository {
  private readonly _api: IApi;
  private readonly _apiGetSupplies: string;
  private readonly _apiGetSuppliesFilters: string;
  private readonly _apiGetRequestsStatus: string;
  private readonly _apiGetClosedStatus: string;
  private readonly _apiGetCriticalStock: string;
  private readonly _apiGetCriticalStockSummary: string;
  private readonly _apiGetWarehousesTarget: string;
  private readonly _apiGetDownloadSupplies: string;
  private readonly _apiGetWarehousesRanking: string;
  private readonly _apiGetSubzonesRanking: string;

  constructor({
    api,
    apiGetSupplies,
    apiGetRequestsStatus,
    apiGetSuppliesFilters,
    apiGetClosedStatus,
    apiGetCriticalStock,
    apiGetCriticalStockSummary,
    apiGetWarehousesTarget,
    apiGetDownloadSupplies,
    apiGetWarehousesRanking,
    apiGetSubzonesRanking
  }) {
    super();
    this._api = api;
    this._apiGetSupplies = apiGetSupplies;
    this._apiGetSuppliesFilters = apiGetSuppliesFilters;
    this._apiGetRequestsStatus = apiGetRequestsStatus;
    this._apiGetClosedStatus = apiGetClosedStatus;
    this._apiGetCriticalStock = apiGetCriticalStock;
    this._apiGetCriticalStockSummary = apiGetCriticalStockSummary;
    this._apiGetWarehousesTarget = apiGetWarehousesTarget;
    this._apiGetDownloadSupplies = apiGetDownloadSupplies;
    this._apiGetWarehousesRanking = apiGetWarehousesRanking;
    this._apiGetSubzonesRanking = apiGetSubzonesRanking;
  }

  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetSupplies}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new SuppliesDataDTO(res));
  }

  getSuppliesFilters() {
    return this._api
      .get(this._apiGetSuppliesFilters)
      .then((res) => new SuppliesFiltersDataDTO(res));
  }

  getRequestsStatus({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetRequestsStatus}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new SuppliesRequestsStatusDTO(res));
  }

  getClosedStatus({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetClosedStatus}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new SuppliesRequestsStatusDTO(res));
  }

  getCriticalStock(): Promise<CriticalPiecesSummary> {
    return this._api
      .get(this._apiGetCriticalStock)
      .then((data) => criticalPiecesSummaryDTOMapper(data))
      .then((dto) => criticalPiecesSummaryMapper(dto));
  }

  getCriticalStockSummary({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetCriticalStockSummary}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => new SummaryCriticalStockDTO(res));
  }

  getWarehousesTarget({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetWarehousesTarget}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => warehouseRankingDTOMapper(res));
  }

  getWarehousesOriginRanking({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetWarehousesRanking}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => warehouseRankingDTOMapper(res));
  }

  getSubzonesRanking({
    queryParams
  }: {
    queryParams: object;
  }): Promise<WarehouseRankingDTO> {
    const url = `${this._apiGetSubzonesRanking}${this._createQueryParams(
      queryParams
    )}`;
    return this._api.get(url).then((res) => warehouseRankingDTOMapper(res));
  }

  downloadSupplies({ queryParams }) {
    const url = `${this._apiGetDownloadSupplies}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new SuppliesRepository({
  api: api,
  apiGetSupplies: API_GET_SUPPLIES,
  apiGetSuppliesFilters: API_GET_SUPPLIES_FILTERS,
  apiGetRequestsStatus: API_GET_REQUESTS_STATUS,
  apiGetClosedStatus: API_GET_CLOSED_STATUS,
  apiGetCriticalStockSummary: API_GET_SUPPLIES_CRITICAL_STOCK_SUMMARY,
  apiGetWarehousesTarget: API_GET_WAREHOUSES_TARGET,
  apiGetDownloadSupplies: API_DOWNLOAD_SUPPLIES,
  apiGetWarehousesRanking: API_GET_WAREHOUSES_RANKING,
  apiGetCriticalStock: API_GET_SUPPLIES_CRITICAL_STOCK,
  apiGetSubzonesRanking: API_GET_SUBZONES_RANKING
});

export { SuppliesRepository };
