import SuppliesFilters from '../../supplies/domain/SuppliesFilters';
import suppliesRepository from 'modules/inbound/supplies/infrastructure/repositories/SuppliesRepository';
import ISuppliesRepository from '../../supplies/domain/ISuppliesRepository';

class GetSuppliesFiltersService {
  private readonly suppliesRepository: ISuppliesRepository;
  constructor({ repository }) {
    this.suppliesRepository = repository;
  }

  execute() {
    return this.suppliesRepository
      .getSuppliesFilters()
      .then((res) => SuppliesFilters.generateFromDTO(res));
  }
}

export default new GetSuppliesFiltersService({
  repository: suppliesRepository
});

export { GetSuppliesFiltersService };
