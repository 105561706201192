import SuppliesData from '../domain/SuppliesData';
import ISuppliesRepository from '../domain/ISuppliesRepository';
import suppliesRepository from '../infrastructure/repositories/SuppliesRepository';

class GetSuppliesDataService {
  private readonly _suppliesRepository: ISuppliesRepository;

  constructor({ repository }) {
    this._suppliesRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._suppliesRepository
      .get({
        queryParams
      })
      .then((res) => SuppliesData.generateFromDTO(res));
  }
}

export default new GetSuppliesDataService({ repository: suppliesRepository });

export { GetSuppliesDataService };
