import IEntriesRepository from '../../domain/IEntriesRepository';
import { EntriesBySlotAndUnloadingPoint } from '../../domain/UnloadingPoints/entriesBySlotAndUnloadingPointMapper';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesBySlotAndUnloadingPointUseCase {
  private readonly _entriesDataRepository: IEntriesRepository;

  constructor({ repository }) {
    this._entriesDataRepository = repository;
  }

  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<EntriesBySlotAndUnloadingPoint> {
    return this._entriesDataRepository.getEntriesBySlotAndUnloadingPoint({
      queryParams
    });
  }
}

const getEntriesBySlotAndUnloadingPointUseCase =
  new GetEntriesBySlotAndUnloadingPointUseCase({
    repository: entriesRepository
  });

export {
  GetEntriesBySlotAndUnloadingPointUseCase,
  getEntriesBySlotAndUnloadingPointUseCase
};
