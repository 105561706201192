import { ManageMastersUrls } from '../../domain/MastersPanel';

const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const MASTER = '/master/';
const INFO = 'info';
const RESPONSIBLE = 'master-responsible';
const API_GET_MASTER_INFO = `${INBOUND_BASE_URL}${MASTER}${INFO}`;
const API_MANAGE_MASTER = (manageMasterUrl: ManageMastersUrls) =>
  `${INBOUND_BASE_URL}${MASTER}${manageMasterUrl}`;
const API_PUT_MASTER_RESPONSIBLE = `${INBOUND_BASE_URL}${MASTER}${RESPONSIBLE}`;

export { API_MANAGE_MASTER, API_GET_MASTER_INFO, API_PUT_MASTER_RESPONSIBLE };
