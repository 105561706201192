import IFactoryBcnDataRepository from '../domain/IFactoryBcnDataRepository';
import factoryBCNRepository from '../infrastructure/repositories/FactoryBCNRepository';

class DownloadFactoryBCNUseCase {
  private readonly _factoryBCNRepository: IFactoryBcnDataRepository;

  constructor({ factoryBCNRepository }) {
    this._factoryBCNRepository = factoryBCNRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._factoryBCNRepository.downloadFactoryBCN({
      queryParams
    });
  }
}

export default new DownloadFactoryBCNUseCase({
  factoryBCNRepository: factoryBCNRepository
});

export { DownloadFactoryBCNUseCase };
