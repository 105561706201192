import { EntriesInPlantFilters } from '../../domain/InPlant/EntriesInPlantFilters';
import IEntriesRepository from '../../domain/IEntriesRepository';
import entriesRepository from '../../infrastructure/repositories/EntriesRepository';

class GetEntriesInPlantFiltersUseCase {
  private readonly _repository: IEntriesRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<EntriesInPlantFilters> {
    return this._repository.getEntriesInPlantFilters();
  }
}

const getEntriesInPlantFiltersUseCase = new GetEntriesInPlantFiltersUseCase({
  repository: entriesRepository
});
export { GetEntriesInPlantFiltersUseCase, getEntriesInPlantFiltersUseCase };
