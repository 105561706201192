import DeliveriesGeolocationCheckpointDTO from 'modules/outbound/fulfillment/infrastructure/dto/DeliveriesGeolocationCheckpointDTO';
import DeliveriesGeolocationCheckpoint from 'modules/outbound/fulfillment/domain/DeliveriesGeolocationCheckpoint';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';

class DeliveriesGeolocationCheckpointsService {
  private readonly _deliveriesGeolocationRepository: ITransitRepository;

  constructor({ deliveriesGeolocationRepository }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
  }

  get({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    return this._deliveriesGeolocationRepository.getCheckpoints!({
      countryCode,
      queryParams
    })
      .then((res) => ({
        countryCode: res.countryCode,
        checkpoints: res.checkpoints.map((item) =>
          this._mapToDeliveriesGeolocationCheckpoint(item)
        )
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToDeliveriesGeolocationCheckpoint(
    dto: DeliveriesGeolocationCheckpointDTO
  ): DeliveriesGeolocationCheckpoint {
    return DeliveriesGeolocationCheckpoint.generateFromDTO(dto);
  }
}

export default new DeliveriesGeolocationCheckpointsService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository
});

export { DeliveriesGeolocationCheckpointsService };
