import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import {
  GET_LONG_TERM_CAPACITIES_DASHBOARD,
  GET_MID_TERM_CAPACITIES_DASHBOARD,
  GET_TOP_FLOWS_LACK_CAPACITIES_ENDPOINT_DASHBOARD
} from './urls';
import { ITransportCapacityRepository } from 'modules/outbound/capacities/domain/ITransportCapacityRepository';
import TopFlowsLackCapacityDTO from '../dto/TopFlowsLackCapacityDTO';

class TransportCapacityRepository
  extends BaseRepository
  implements ITransportCapacityRepository
{
  private readonly _api: IApi;
  private readonly _getMidTermCapacitiesDashboard: string;
  private readonly _getLongTermCapacitiesDashboard: string;
  private readonly _getTopFlowsLackCapacities: string;

  constructor({
    api,
    getMidTermCapacitiesDashboard,
    getLongTermCapacitiesDashboard,
    getTopFlowsLackCapacities
  }) {
    super();
    this._api = api;
    this._getMidTermCapacitiesDashboard = getMidTermCapacitiesDashboard;
    this._getLongTermCapacitiesDashboard = getLongTermCapacitiesDashboard;
    this._getTopFlowsLackCapacities = getTopFlowsLackCapacities;
  }
  getMidTermCapacitiesDashboardUrl() {
    return this._api
      .get(this._getMidTermCapacitiesDashboard)
      .then((response) => response.url);
  }

  getLongTermCapacitiesDashboardUrl() {
    return this._api
      .get(this._getLongTermCapacitiesDashboard)
      .then((response) => response.url);
  }

  getTopFlowsLackCapacities({ queryParams }: { queryParams: object }) {
    const url = `${this._getTopFlowsLackCapacities}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((response) => new TopFlowsLackCapacityDTO(response));
  }
}

export const transportCapacityRepository = new TransportCapacityRepository({
  api,
  getMidTermCapacitiesDashboard: GET_MID_TERM_CAPACITIES_DASHBOARD,
  getLongTermCapacitiesDashboard: GET_LONG_TERM_CAPACITIES_DASHBOARD,
  getTopFlowsLackCapacities: GET_TOP_FLOWS_LACK_CAPACITIES_ENDPOINT_DASHBOARD
});

export { TransportCapacityRepository };
