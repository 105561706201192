import { stockRepository } from 'modules/outbound/shared/infrastructure/repositories/StockRepository';
import IStockRepository from 'modules/outbound/shared/domain/IStockRepository';
import StockSaturation from '../domain/StockSaturation';

class GetStockSaturationUseCase {
  private readonly _repository: IStockRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute() {
    return this._repository
      .getStockSaturation()
      .then((res) => StockSaturation.generateFromDTO(res));
  }
}

export default new GetStockSaturationUseCase({
  repository: stockRepository
});

export { GetStockSaturationUseCase };
