import { ITransportCapacityRepository } from '../domain/ITransportCapacityRepository';
import { transportCapacityRepository } from '../infrastructure/repositories/TransportCapacityRepository';

class GetMidTermCapacitiesDashboardUseCase {
  private readonly _repository: ITransportCapacityRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute(): Promise<string> {
    return this._repository.getMidTermCapacitiesDashboardUrl();
  }
}

export const getMidTermCapacitiesDashboardUseCase =
  new GetMidTermCapacitiesDashboardUseCase({
    repository: transportCapacityRepository
  });

export { GetMidTermCapacitiesDashboardUseCase };
