import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import deliveriesGeolocationRepository from 'modules/outbound/fulfillment/infrastructure/repositories/DeliveriesGeolocationRepository';
import FinishedGoodCheckpointsTimeline from 'modules/outbound/fulfillment/domain/FinishedGoodCheckpointsTimeline';
import { IFinishedGoodCheckpointsTimelineDTO } from 'modules/outbound/fulfillment/infrastructure/dto/FinishedGoodCheckpointsTimelineDTO';

class FinishedGoodCheckpointTimelineService {
  private readonly _deliveriesGeolocationRepository: ITransitRepository;

  constructor({ deliveriesGeolocationRepository }) {
    this._deliveriesGeolocationRepository = deliveriesGeolocationRepository;
  }

  get({ vin, queryParams }: { vin: string; queryParams?: object }) {
    return this._deliveriesGeolocationRepository
      .getFinishedGoodCheckpointsTimeline!({
      vin,
      queryParams
    }).then((res) =>
      this._mapToFinishedGoodCheckpointsTimeline({ dto: res, vin })
    );
  }

  private _mapToFinishedGoodCheckpointsTimeline({
    dto,
    vin
  }: {
    dto: IFinishedGoodCheckpointsTimelineDTO;
    vin: string;
  }): FinishedGoodCheckpointsTimeline {
    return FinishedGoodCheckpointsTimeline.generateFromDTO({ ...dto, vin });
  }
}

export default new FinishedGoodCheckpointTimelineService({
  deliveriesGeolocationRepository: deliveriesGeolocationRepository
});

export { FinishedGoodCheckpointTimelineService };
