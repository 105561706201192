import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import { IEntriesRepository } from 'modules/inbound/entries/domain/IEntriesRepository';
import * as URL from './urls';
import IncomingMaterialsDTO from '../dto/NextHoursDTO/IncomingMaterialsDTO';
import { entriesDataDTOMapper } from '../dto/NextHoursDTO/entriesDataDTOMapper';
import EntriesFiltersDataDTO from '../dto/NextHoursDTO/EntriesFiltersDTO';
import EntriesInPlantDataDTO from '../dto/InPlantDTO/EntriesInPlantDataDTO';
import EntriesWarehouseDlzDetailDTO from '../dto/InPlantDTO/EntriesWarehouseDlzDetailDTO';
import EntryInPlantDetailDTO from '../dto/InPlantDTO/EntryInPlantDetailDTO';
import { EntriesParalyzationDTO } from '../dto/InPlantDTO/EntriesParalyzationDTO';
import {
  EntriesInPlantFiltersDTO,
  entriesInPlantFiltersDTOMapper
} from '../dto/InPlantDTO/entriesInPlantFiltersDTOMapper';
import { entriesInPlantFiltersMapper } from '../../domain/InPlant/entriesInPlantFiltersMapper';
import { entriesDataMapper } from '../../domain/NextHours/entriesDataMaper';
import { urgentIncomingTrucksMapper } from '../../domain/NextHours/urgentIncomingTrucksMapper';
import {
  UrgentIncomingTrucksDTO,
  urgentIncomingTrucksDTOMapper
} from '../dto/NextHoursDTO/urgentIncomingTrucksDTOMapper';
import { entryDetailDTOMapper } from '../dto/NextHoursDTO/entryDetailDTOMapper';
import {
  EntriesSummaryDTO,
  entriesSummaryDTOMapper
} from '../dto/NextHoursDTO/entriesSummaryDTOMapper';
import { entriesSummaryMapper } from '../../domain/NextHours/entriesSummaryMapper';
import { unloadingPointsDTOMapper } from '../dto/UnloadingPointsDTO/unloadingPointsDTOMapper';
import { unloadingPointsMapper } from '../../domain/UnloadingPoints/unloadingPointsMapper';
import { entriesBySlotAndUnloadingPointDTOMapper } from '../dto/UnloadingPointsDTO/entriesBySlotAndUnloadingPointDTOMapper';
import { entriesBySlotAndUnloadingPointMapper } from '../../domain/UnloadingPoints/entriesBySlotAndUnloadingPointMapper';
import { SaturationUnloadingPointsFiltersDTO } from '../dto/UnloadingPointsDTO/SaturationUnloadingPointsFiltersDTO';
import { SaturationUnloadingPointsFilters } from '../../domain/UnloadingPoints/SaturationUnloadingPointsFilters';
import { unloadingPointPiecesDTOMapper } from '../dto/UnloadingPointsDTO/unloadingPointPiecesDTOMapper';
import { unloadingPointPiecesMapper } from '../../domain/UnloadingPoints/unloadingPointPiecesMapper';

class EntriesRepository extends BaseRepository implements IEntriesRepository {
  private readonly _api: IApi;
  private readonly _apiGetEntriesUrl: string;
  private readonly _apiGetEntriesInPlantUrl: string;
  private readonly _apiGetEntriesFilter: string;
  private readonly _getIncomingMaterialsUrl: string;
  private readonly _getParalyzationSummary: string;
  private readonly _apiGetEntriesInPlantFilters: string;
  private readonly _apiGetEntriesSummary: string;
  private readonly _apiGetGlobalDlzSummary: string;
  private readonly _apiSaturationGetUnloadingPoints: string;
  private readonly _apiGetEntriesBySlotAndUnloadingPoint: string;
  private readonly _apiGetSaturationUnloadingPointsFilters: string;
  private readonly _apiGetDownloadEntries: string;
  private readonly _apiGetDownloadEntriesInPlant: string;
  private readonly _apiGetEntriesUrgentTrucks: string;

  constructor({
    api,
    apiGetEntries,
    apiGetEntriesInPlantUrl,
    apiGetEntriesFilter,
    getIncomingMaterialsUrl,
    getParalyzationSummary,
    apiGetEntriesInPlantFilters,
    apiGetEntriesSummary,
    apiGetGlobalDlzSummary,
    apiSaturationGetUnloadingPoints,
    apiGetEntriesBySlotAndUnloadingPoint,
    apiGetSaturationUnloadingPointsFilters,
    apiGetDownloadEntries,
    apiGetDownloadEntriesInPlant,
    apiGetEntriesUrgentTrucks
  }) {
    super();
    this._api = api;
    this._apiGetEntriesUrl = apiGetEntries;
    this._apiGetEntriesInPlantUrl = apiGetEntriesInPlantUrl;
    this._apiGetEntriesFilter = apiGetEntriesFilter;
    this._getIncomingMaterialsUrl = getIncomingMaterialsUrl;
    this._getParalyzationSummary = getParalyzationSummary;
    this._apiGetEntriesInPlantFilters = apiGetEntriesInPlantFilters;
    this._apiGetEntriesSummary = apiGetEntriesSummary;
    this._apiGetGlobalDlzSummary = apiGetGlobalDlzSummary;
    this._apiSaturationGetUnloadingPoints = apiSaturationGetUnloadingPoints;
    this._apiGetEntriesBySlotAndUnloadingPoint =
      apiGetEntriesBySlotAndUnloadingPoint;
    this._apiGetSaturationUnloadingPointsFilters =
      apiGetSaturationUnloadingPointsFilters;
    this._apiGetDownloadEntries = apiGetDownloadEntries;
    this._apiGetDownloadEntriesInPlant = apiGetDownloadEntriesInPlant;
    this._apiGetEntriesUrgentTrucks = apiGetEntriesUrgentTrucks;
  }

  getEntriesStock({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetEntriesUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => entriesDataDTOMapper(res))
      .then((res) => entriesDataMapper(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getEntriesInPlantStock({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetEntriesInPlantUrl}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => new EntriesInPlantDataDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getEntryDetail({ transportId }: { transportId: number }) {
    return this._api
      .get(`${this._apiGetEntriesUrl}/${transportId}/detail`)
      .then((res: any) => entryDetailDTOMapper(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getEntryWarehouseDlzDetail({ transportId }: { transportId: number }) {
    return this._api
      .get(`${this._apiGetEntriesInPlantUrl}/${transportId}/warehouse-dlz`)
      .then((res) => new EntriesWarehouseDlzDetailDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getEntriesFilters() {
    return this._api
      .get(this._apiGetEntriesFilter)
      .then((res) => new EntriesFiltersDataDTO(res));
  }

  getEntryInPlantDetail({ transportId }: { transportId: number }) {
    return this._api
      .get(`${this._apiGetEntriesInPlantUrl}/${transportId}/detail`)
      .then((res) => new EntryInPlantDetailDTO(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getIncomingMaterials({ queryParams }: { queryParams: object }) {
    return this._api
      .get(
        `${this._getIncomingMaterialsUrl}${this._createQueryParams(
          queryParams
        )}`
      )
      .then((res) => new IncomingMaterialsDTO(res));
  }

  getParalyzationSummary() {
    return this._api
      .get(this._getParalyzationSummary)
      .then((res) => new EntriesParalyzationDTO(res));
  }

  getEntriesInPlantFilters() {
    return this._api
      .get(this._apiGetEntriesInPlantFilters)
      .then((res: any) => entriesInPlantFiltersDTOMapper(res))
      .then((dto: EntriesInPlantFiltersDTO) =>
        entriesInPlantFiltersMapper(dto)
      );
  }

  getUrgentIncomingTrucks() {
    return this._api
      .get(this._apiGetEntriesUrgentTrucks)
      .then((res: any) => urgentIncomingTrucksDTOMapper(res))
      .then((dto: UrgentIncomingTrucksDTO) => urgentIncomingTrucksMapper(dto));
  }

  getEntriesSummary({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetEntriesSummary}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res: any) => entriesSummaryDTOMapper(res))
      .then((dto: EntriesSummaryDTO) => entriesSummaryMapper(dto));
  }

  getDlzSummary() {
    return this._api.get(this._apiGetGlobalDlzSummary).then((res) => res.url);
  }

  getSaturationUnloadingPoints({ queryParams }: { queryParams: object }) {
    const url = `${
      this._apiSaturationGetUnloadingPoints
    }${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => unloadingPointsDTOMapper(res))
      .then((dto) => unloadingPointsMapper(dto));
  }

  getEntriesBySlotAndUnloadingPoint({ queryParams }: { queryParams: object }) {
    const url = `${
      this._apiGetEntriesBySlotAndUnloadingPoint
    }${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => entriesBySlotAndUnloadingPointDTOMapper(res))
      .then((res) => entriesBySlotAndUnloadingPointMapper(res));
  }

  getPiecesByEntryAndUnloadingPoint({
    queryParams,
    transportId
  }: {
    queryParams: object;
    transportId: number;
  }) {
    const url = `${
      this._apiGetEntriesBySlotAndUnloadingPoint
    }/${transportId}/pieces${this._createQueryParams(queryParams)}`;

    return this._api
      .get(url)
      .then((res) => unloadingPointPiecesDTOMapper(res))
      .then((dto) => unloadingPointPiecesMapper(dto));
  }

  getSaturationUnloadingPointsFilters() {
    return this._api
      .get(this._apiGetSaturationUnloadingPointsFilters)
      .then((res) => new SaturationUnloadingPointsFiltersDTO(res))
      .then((dto) => SaturationUnloadingPointsFilters.generateFromDTO(dto));
  }

  downloadEntries({ queryParams }) {
    const url = `${this._apiGetDownloadEntries}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  downloadEntriesInPlant({ queryParams }) {
    const url = `${this._apiGetDownloadEntriesInPlant}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new EntriesRepository({
  api: api,
  apiGetEntries: URL.API_GET_ENTRIES_STOCK,
  apiGetEntriesInPlantUrl: URL.API_GET_ENTRIES_IN_PLANT,
  apiGetEntriesFilter: URL.API_GET_ENTRIES_FILTERS,
  getIncomingMaterialsUrl: URL.INCOMING_MATERIALS_ENDPOINT,
  getParalyzationSummary: URL.API_GET_ENTRIES_PARALYZATION_SUMMARY,
  apiGetEntriesInPlantFilters: URL.API_GET_ENTRIES_IN_PLANT_FILTERS,
  apiGetEntriesSummary: URL.API_GET_ENTRIES_SUMMARY,
  apiGetGlobalDlzSummary: URL.API_GET_GLOBAL_DLZ_SUMMARY,
  apiSaturationGetUnloadingPoints: URL.API_GET_SATURATION_UNLOADING_POINTS,
  apiGetEntriesBySlotAndUnloadingPoint:
    URL.API_GET_ENTRIES_BY_SLOT_AND_SATURATION_POINT,
  apiGetSaturationUnloadingPointsFilters:
    URL.API_GET_SATURATION_UNLOADING_POINTS_FILTERS,
  apiGetDownloadEntries: URL.API_DOWNLOAD_ENTRIES,
  apiGetDownloadEntriesInPlant: URL.API_DOWNLOAD_ENTRIES_IN_PLANT,
  apiGetEntriesUrgentTrucks: URL.API_GET_URGENT_TRUCKS
});

export { EntriesRepository };
