import {
  API_GET_CLOSE_CONTAINER,
  API_GET_FAMILY_COVERAGE,
  API_GET_JIT_FAMILIES,
  API_GET_JIT_FILTERS,
  API_GET_STOCK_JIT_BY_PROVIDER,
  API_GET_GENERAL_DATA,
  API_GET_JIT_FAMILIES_FILTERS,
  API_DOWNLOAD_JIT_COVERAGE_PARTS
} from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import PartCoverageDTO from '../dto/PartCoverageDTO';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IJitRepository from '../../domain/IJitRepository';
import JitFamiliesDTO from '../dto/FamiliesCoverageDTO';
import JitFamilyCoverageDTO from '../dto/JitFamilyCoverageDTO';
import JitFiltersDataDTO from '../dto/PartCoverageFiltersDTO';
import { jitCloseContainersDTOMapper } from '../dto/JitCloseContainerDTO';
import JitGeneralDataDTO from '../dto/JitGeneralDataDTO';
import JitFamiliesFiltersDTO from '../dto/FamiliesCoverageFiltersDTO';

class JitRepository extends BaseRepository implements IJitRepository {
  private readonly _api: IApi;
  private readonly _apiGetJitStockByProvider: (providerName: string) => string;
  private readonly _apiGetJitFilters: string;
  private readonly _apiGetJitFamilyCoverage: string;
  private readonly _apiGetJitFamilies: string;
  private readonly _apiGetJitCC: string;
  private readonly _apiGetJitGeneralData: string;
  private readonly _apiGetJitFamiliesFilters: string;
  private readonly _apiGetDownloadJitStock: (username: string) => string;

  constructor({
    api,
    apiGetJitStockByProvider,
    apiGetJitFilters,
    apiGetJitFamilyCoverage,
    apiGetJitFamilies,
    apiGetJitCC,
    apiGetJitGeneralData,
    apiGetJitFamiliesFilters,
    apiGetDownloadJitStock
  }) {
    super();
    this._api = api;
    this._apiGetJitStockByProvider = apiGetJitStockByProvider;
    this._apiGetJitFilters = apiGetJitFilters;
    this._apiGetJitFamilyCoverage = apiGetJitFamilyCoverage;
    this._apiGetJitFamilies = apiGetJitFamilies;
    this._apiGetJitCC = apiGetJitCC;
    this._apiGetJitGeneralData = apiGetJitGeneralData;
    this._apiGetJitFamiliesFilters = apiGetJitFamiliesFilters;
    this._apiGetDownloadJitStock = apiGetDownloadJitStock;
  }

  get({ queryParams, username }: { queryParams: object; username: string }) {
    const url = `${this._apiGetJitStockByProvider(
      username
    )}${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new PartCoverageDTO(res));
  }

  getPartCoverageFilters({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetJitFilters}${this._createQueryParams(
      queryParams
    )}`;

    return this._api.get(url).then((res) => new JitFiltersDataDTO(res));
  }

  getFamilyCoverage() {
    const url = this._apiGetJitFamilyCoverage;
    return this._api.get(url).then((res) => new JitFamilyCoverageDTO(res));
  }

  getFamilies({ queryParams }: { queryParams: object }) {
    return this._api
      .get(`${this._apiGetJitFamilies}${this._createQueryParams(queryParams)}`)
      .then((res) => new JitFamiliesDTO(res));
  }

  getCloseContainer({ queryParams }: { queryParams: object }) {
    return this._api
      .get(`${this._apiGetJitCC}${this._createQueryParams(queryParams)}`)
      .then((res) => jitCloseContainersDTOMapper(res));
  }

  getGeneralData({ queryParams }: { queryParams: object }) {
    return this._api
      .get(
        `${this._apiGetJitGeneralData}${this._createQueryParams(queryParams)}`
      )
      .then((res) => new JitGeneralDataDTO(res));
  }

  getJitFamiliesFilters({ queryParams }: { queryParams: object }) {
    const url = `${this._apiGetJitFamiliesFilters}${this._createQueryParams(
      queryParams
    )}`;

    return this._api.get(url).then((res) => new JitFamiliesFiltersDTO(res));
  }

  downloadJitStock({
    queryParams,
    username
  }: {
    queryParams: object;
    username: string;
  }) {
    const url = `${this._apiGetDownloadJitStock(
      username
    )}${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new JitRepository({
  api: api,
  apiGetJitStockByProvider: API_GET_STOCK_JIT_BY_PROVIDER,
  apiGetJitFilters: API_GET_JIT_FILTERS,
  apiGetJitFamilyCoverage: API_GET_FAMILY_COVERAGE,
  apiGetJitFamilies: API_GET_JIT_FAMILIES,
  apiGetJitCC: API_GET_CLOSE_CONTAINER,
  apiGetJitGeneralData: API_GET_GENERAL_DATA,
  apiGetJitFamiliesFilters: API_GET_JIT_FAMILIES_FILTERS,
  apiGetDownloadJitStock: API_DOWNLOAD_JIT_COVERAGE_PARTS
});

export { JitRepository };
