import IncommingTransfers, {
  IncomingTransfersTimeRange
} from 'modules/inbound/transfer/domain/IncomingTransfers';
import ITransferRepository from '../domain/ITransferRepository';
import transferRepository from '../infrastructure/repositories/TransferRepository';

class GetIncomingTransfersUseCase {
  private readonly _repository: ITransferRepository;

  constructor({ repository }) {
    this._repository = repository;
  }

  execute({
    timeRange,
    queryParams
  }: {
    timeRange: IncomingTransfersTimeRange;
    queryParams: object;
  }): Promise<IncommingTransfers> {
    return this._repository
      .getIncomingTransfers({ timeRange, queryParams })
      .then((res) => IncommingTransfers.generateFromDTO(res));
  }
}

export default new GetIncomingTransfersUseCase({
  repository: transferRepository
});

export { GetIncomingTransfersUseCase };
