import IProviderContactListRepository from '../../domain/ProviderContactList/IProviderContactList';
import ProviderContactListRepository from '../../infraestructure/repositories/ProviderContactListRepository';

class CreateNewContactUseCase {
  private readonly _providerContactListRepository: IProviderContactListRepository;

  constructor({ repository }) {
    this._providerContactListRepository = repository;
  }

  execute({
    providerId,
    body
  }: {
    providerId: number;
    body: {
      name: string;
      telephone: string;
      email: string;
      role: string;
    };
  }) {
    return this._providerContactListRepository
      .postNewContact({ providerId, body })
      .then((res) => res);
  }
}

export { CreateNewContactUseCase };
export default new CreateNewContactUseCase({
  repository: ProviderContactListRepository
});
