import { ITransportCapacityRepository } from '../domain/ITransportCapacityRepository';
import { transportCapacityRepository } from '../infrastructure/repositories/TransportCapacityRepository';
import TopFlowsLackCapacity from '../domain/TopFlowsLackCapacity';

class GetTopFlowsLackCapacitiesUseCase {
  private readonly _repository: ITransportCapacityRepository;

  constructor({ repository }) {
    this._repository = repository;
  }
  execute({
    queryParams
  }: {
    queryParams: object;
  }): Promise<TopFlowsLackCapacity> {
    return this._repository
      .getTopFlowsLackCapacities({ queryParams })
      .then((res) => TopFlowsLackCapacity.generateFromDTO(res));
  }
}

export const getTopFlowsLackCapacitiesUseCase =
  new GetTopFlowsLackCapacitiesUseCase({
    repository: transportCapacityRepository
  });

export { GetTopFlowsLackCapacitiesUseCase };
