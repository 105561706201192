import callOffRepository from 'modules/outbound/callOff/infrastructure/repositories/CallOffRepository';
import ICallOffRepository from 'modules/outbound/callOff/domain/ICallOffRepository';
import Checkpoint from 'modules/outbound/callOff/domain/Checkpoint';

class CheckpointsService {
  private readonly _callOffRepository: ICallOffRepository;

  constructor({ repository }) {
    this._callOffRepository = repository;
  }

  execute({
    countryCode,
    queryParams
  }: {
    countryCode: string;
    queryParams: object;
  }) {
    return this._callOffRepository
      .getCheckpoints({
        countryCode,
        queryParams
      })
      .then((res) => res.map((item) => Checkpoint.generateFromDTO(item)));
  }
}

export default new CheckpointsService({
  repository: callOffRepository
});

export { CheckpointsService };
