import {
  LoadTransportDTO,
  MaterialResourcePlanDTO,
  PlanningDTO
} from 'modules/inbound/shared/infraestructure/dto/PlanningTransportsDTO';

class NoJitKeyPlanningDTO {
  planning: PlanningDTO<LoadTransportDTO>[];
  past_loads: LoadTransportDTO[];

  constructor({ planning, past_loads }) {
    this.planning = planning;
    this.past_loads = past_loads;
  }

  private static _generateLoad(load: any): LoadTransportDTO {
    return {
      amount: load.amount ?? 0,
      departure_date: load.departure_date ?? '',
      official_delivery_time: load.official_delivery_time,
      window_booked_id: load.window_booked_id ?? null,
      provider_delivery_time: load.provider_delivery_time,
      is_delayed: load.is_delayed ?? false,
      is_urgent: load.is_urgent ?? false,
      planned_date: load.planned_date ?? null,
      plate_number: load.plate_number ?? '',
      provider_delivery_note: load.provider_delivery_note ?? '',
      provider_id: load.provider_id,
      transporter: load.transporter ?? null,
      type: load.type ?? '',
      leadtime: load.leadtime ?? null,
      is_kcc: load.is_kcc ?? false
    };
  }

  private static _generateMrp(mrp: any): MaterialResourcePlanDTO {
    return {
      amount: mrp.amount ?? 0,
      leadtime: mrp.leadtime ?? 0,
      planning_state: mrp.planning_state ?? '',
      provider_id: mrp.provider_id ?? 0,
      end_planning_date: mrp.end_planning_date ?? ''
    };
  }

  private static _generatePlanning(
    planning: any
  ): PlanningDTO<LoadTransportDTO> {
    return {
      amount: planning.amount ?? 0,
      coverage_segments:
        planning.coverage_segments?.map((coverage) => ({
          percentage: coverage.percentage ?? 0,
          status: coverage.status ?? ''
        })) ?? [],
      date: planning.date ?? '',
      loads: planning.loads?.map((load) => this._generateLoad(load)) ?? [],
      mrps: planning.mrps?.map((mrp) => this._generateMrp(mrp)) ?? []
    };
  }

  static generateDTO(data: any): NoJitKeyPlanningDTO {
    return new NoJitKeyPlanningDTO({
      planning:
        data.planning?.map((item) => this._generatePlanning(item)) ?? [],
      past_loads:
        data.past_loads?.map((past_load) => this._generateLoad(past_load)) ?? []
    });
  }
}

export { NoJitKeyPlanningDTO };
