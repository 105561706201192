import RefillsData from '../domain/RefillsData';
import IRefillsRepository from '../domain/IRefillsRepository';
import refillsRepository from '../infrastructure/repositories/RefillsRepository';

class GetRefillsDataService {
  private readonly _refillsRepository: IRefillsRepository;

  constructor({ repository }) {
    this._refillsRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._refillsRepository
      .get({
        queryParams
      })
      .then((res) => RefillsData.generateFromDTO(res));
  }
}

export default new GetRefillsDataService({
  repository: refillsRepository
});

export { GetRefillsDataService };
