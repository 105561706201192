import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import {
  API_GET_STOCK_AGING,
  API_GET_STOCK_OCCUPATION,
  API_GET_STOCK_SATURATION_OCCUPATION
} from './urls';
import { stockAgingDTOMapper } from 'modules/outbound/shared/application/stockAgingDTOMapper';
import IStockRepository from 'modules/outbound/shared/domain/IStockRepository';
import { stockSaturationDTOMapper } from '../../application/stockSaturationDTOMapper';
import { stockOccupationDTOMapper } from '../../application/stockOccupationDTOMapper';

class StockRepository extends BaseRepository implements IStockRepository {
  private readonly _api: IApi;
  private readonly _apiGetStockAging: string;
  private readonly _apiGetStockOccupation: string;
  private readonly _apiGetStockSaturationOccupation: string;

  constructor({
    api,
    apiGetStockAging,
    apiGetStockOccupation,
    apiGetStockSaturationOccupation
  }) {
    super();
    this._api = api;
    this._apiGetStockAging = apiGetStockAging;
    this._apiGetStockOccupation = apiGetStockOccupation;
    this._apiGetStockSaturationOccupation = apiGetStockSaturationOccupation;
  }
  getStockAging() {
    return this._api
      .get(this._apiGetStockAging)
      .then((response) => stockAgingDTOMapper(response));
  }

  getStockOccupation() {
    const url = `${this._apiGetStockOccupation}`;

    return this._api
      .get(url)
      .then((res) => stockOccupationDTOMapper(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  getStockSaturation() {
    return this._api
      .get(this._apiGetStockSaturationOccupation)
      .then((response) => stockSaturationDTOMapper(response));
  }
}

export const stockRepository = new StockRepository({
  api,
  apiGetStockAging: API_GET_STOCK_AGING,
  apiGetStockOccupation: API_GET_STOCK_OCCUPATION,
  apiGetStockSaturationOccupation: API_GET_STOCK_SATURATION_OCCUPATION
});

export { StockRepository };
