import IDirectDeliveryRepository from '../domain/IDirectDeliveryRepository';
import directDeliveryRepository from '../infrastructure/repositories/DirectDeliveryRepository';

class DownloadHistoryUseCase {
  private readonly _directDeliveryRepository: IDirectDeliveryRepository;

  constructor({ directDeliveryRepository }) {
    this._directDeliveryRepository = directDeliveryRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._directDeliveryRepository.downloadHistory({
      queryParams
    });
  }
}

export default new DownloadHistoryUseCase({
  directDeliveryRepository: directDeliveryRepository
});

export { DownloadHistoryUseCase };
