import {
  API_POST_LOGIN_WEB,
  INBOUND_USERS
} from 'modules/inbound/shared/infraestructure/repositories/url';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import IUserInboundRepository from '../../domain/UserInbound/IUserInboundRepository';
import UserInboundDTO from '../dto/UserInboundDTO';

class InboundUserRepository implements IUserInboundRepository {
  private readonly _api: IApi;
  private readonly _apiGetUsers: string;
  private readonly _apiPostLoginWeb: string;
  constructor({ api, apiGetUsers, apiPostLoginWeb }) {
    this._api = api;
    this._apiGetUsers = apiGetUsers;
    this._apiPostLoginWeb = apiPostLoginWeb;
  }

  getInboundUserData() {
    const url = this._apiGetUsers;
    return this._api.get(url).then((res) => {
      return new UserInboundDTO(res);
    });
  }

  postLoginWeb() {
    return this._api.post(this._apiPostLoginWeb);
  }
}

export default new InboundUserRepository({
  api: api,
  apiGetUsers: INBOUND_USERS,
  apiPostLoginWeb: API_POST_LOGIN_WEB
});

export { InboundUserRepository };
