import PipelineStockResponseDTO from 'modules/outbound/stock/infrastructure/dto/PipelineStockResponseDTO';
import PipelineStock from 'modules/outbound/stock/domain/PipelineStock';
import IPipelineStockRepository from 'modules/outbound/stock/domain/IPipelineStockRepository';
import pipelineStockRepository from 'modules/outbound/stock/infrastructure/repositories/PipelineStockRepository';

class GetPipelineStockService {
  private readonly _pipelineStockRepository: IPipelineStockRepository;

  constructor({ repository }) {
    this._pipelineStockRepository = repository;
  }

  getAll(queryParams) {
    return this._pipelineStockRepository
      .get({ queryParams })
      .then((res) => this._mapToPipelineStock(res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToPipelineStock(response: PipelineStockResponseDTO) {
    const summary = PipelineStock.generateFromDTO(response.summary);
    const models = response.models.map((model) =>
      PipelineStock.generateFromDTO(model)
    );
    return { summary, models };
  }
}

export default new GetPipelineStockService({
  repository: pipelineStockRepository
});

export { GetPipelineStockService };
