import { INoJitRepository } from '../domain/INoJitRepository';
import noJitRepository from 'modules/inbound/nojit/infrastructure/repositories/NoJitRepository';
import { noJitDataMapper } from '../domain/noJitDataMapper';

class GetNoJitDataService {
  private readonly _noJitRepository: INoJitRepository;

  constructor({ repository }) {
    this._noJitRepository = repository;
  }

  execute({
    queryParams,
    username
  }: {
    queryParams: object;
    username: string;
  }) {
    return this._noJitRepository
      .get({
        queryParams,
        username
      })
      .then((res) => noJitDataMapper(res));
  }
}

export default new GetNoJitDataService({ repository: noJitRepository });

export { GetNoJitDataService };
