import SummaryCriticalStockData from 'modules/inbound/shared/domain/SummaryCriticalStock';
import ITransferRepository from '../domain/ITransferRepository';
import transferRepository from '../infrastructure/repositories/TransferRepository';

class GetIncomingMaterialsCriticalStockUseCase {
  private readonly _transferRepository: ITransferRepository;

  constructor({ transferRepository }) {
    this._transferRepository = transferRepository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._transferRepository
      .getIncomingMaterialsCriticalStock({ queryParams })
      .then((res) => SummaryCriticalStockData.generateFromDTO(res));
  }
}

export default new GetIncomingMaterialsCriticalStockUseCase({
  transferRepository: transferRepository
});

export { GetIncomingMaterialsCriticalStockUseCase };
