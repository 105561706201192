import IWarehouseStockRepository from 'modules/outbound/stock/domain/IWarehouseStockRepository';
import warehouseStockRepository from 'modules/outbound/stock/infrastructure/repositories/WarehouseStockRepository';
import { GetWarehouseByModelService } from 'modules/outbound/stock/services/GetWarehouseByModelService';
import WarehouseForecastByModelDTO from '../infrastructure/dto/WarehouseForecastByModelDTO';
import WarehouseForecastByModel from '../domain/WarehouseForecastByModel';

class GetWarehouseForecastByModelService extends GetWarehouseByModelService {
  private readonly _warehouseStockRepository: IWarehouseStockRepository;

  constructor({ warehouseStockRepository }) {
    super();
    this._warehouseStockRepository = warehouseStockRepository;
  }

  get({
    warehouseReference,
    queryParams
  }: {
    warehouseReference?: string;
    queryParams: object;
  }) {
    const reference = this._getReference(warehouseReference);
    return this._warehouseStockRepository
      .getForecastByWarehouse({ warehouseReference: reference, queryParams })
      .then((res) => this._mapToWarehouseStocksByModel(res))
      .then((res) => ({
        ...res,
        warehouseReference
      }))
      .catch((err) => {
        throw new Error(err);
      });
  }

  private _mapToWarehouseStocksByModel(
    dto: WarehouseForecastByModelDTO
  ): WarehouseForecastByModel {
    return WarehouseForecastByModel.generateFromDTO(dto);
  }
}

export default new GetWarehouseForecastByModelService({
  warehouseStockRepository: warehouseStockRepository
});

export { GetWarehouseForecastByModelService };
