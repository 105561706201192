import { IMasterRepository } from '../domain/IMastersRepository';
import { ManageMastersUrls } from '../domain/MastersPanel';
import masterRepository from '../infrastructure/repositories/MastersRepository';

class DownloadMasterUseCase {
  private readonly _masterRepository: IMasterRepository;

  constructor({ masterRepository }) {
    this._masterRepository = masterRepository;
  }

  execute({
    queryParams,
    manageMasterUrl
  }: {
    queryParams: object;
    manageMasterUrl: ManageMastersUrls;
  }) {
    return this._masterRepository.downloadMaster({
      queryParams,
      manageMasterUrl
    });
  }
}

export default new DownloadMasterUseCase({
  masterRepository: masterRepository
});

export { DownloadMasterUseCase };
