import noJitRepository from 'modules/inbound/nojit/infrastructure/repositories/NoJitRepository';
import { INoJitRepository } from '../domain/INoJitRepository';
import { NoJitPlanning } from '../domain/NoJitPlanning';

class GetNoJitPlanningUseCase {
  private readonly _noJitRepository: INoJitRepository;

  constructor({ repository }) {
    this._noJitRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._noJitRepository
      .getNoJitPlanning({
        queryParams
      })
      .then((res) => NoJitPlanning.generateFromDTO(res));
  }
}

export default new GetNoJitPlanningUseCase({ repository: noJitRepository });

export { GetNoJitPlanningUseCase };
