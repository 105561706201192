import { API_GET_FULFILLMENT, API_GLOBAL_FULFILLMENT } from './urls';
import api from 'modules/shared/infrastructure/api/Api';
import ITransitRepository from 'modules/outbound/fulfillment/domain/ITransitRepository';
import IApi from 'modules/shared/domain/IApi';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import TransitFulfillmentCheckpointDTO from 'modules/outbound/fulfillment/infrastructure/dto/TransitFulfillmentCheckpointDTO';
import TransitFulfillmentResponseDTO from 'modules/outbound/fulfillment/infrastructure/dto/TransitFulfillmentResponseDTO';
import CountryRoutesDTO from 'modules/outbound/fulfillment/infrastructure/dto/CountryRoutesDTO';
import GlobalTransitFulfillmentResponseDTO from '../dto/GlobalTransitFulfillmentResponseDTO';

class FulfillmentRepository
  extends BaseRepository
  implements ITransitRepository
{
  private readonly _api: IApi;
  private readonly _apiGetFullfilments: string;
  private readonly _apiGlobalFulfillments: string;

  constructor({ api, apiGetFullfilments, apiGlobalFulfillments }) {
    super();
    this._api = api;
    this._apiGetFullfilments = apiGetFullfilments;
    this._apiGlobalFulfillments = apiGlobalFulfillments;
  }

  get({ queryParams }) {
    const url = this._apiGetFullfilments + this._createQueryParams(queryParams);
    return this._api
      .get(url)
      .then((res) => new TransitFulfillmentResponseDTO(res));
  }

  getCheckpointsByCountry({ queryParams, countryCode }) {
    const url = `${
      this._apiGetFullfilments
    }/${countryCode}${this._createQueryParams(queryParams)}`;
    return this._api
      .get(url)
      .then((res) =>
        res.map((element) => new TransitFulfillmentCheckpointDTO(element))
      );
  }

  getRoutesByCountry({ queryParams, countryCode }) {
    const url = `${
      this._apiGetFullfilments
    }/${countryCode}/routes${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new CountryRoutesDTO(res));
  }

  downloadFinishedGoods({ queryParams }) {
    const url = `${this._apiGetFullfilments}/export${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  getGlobalKAPFulfillment({ queryParams }) {
    const url =
      this._apiGlobalFulfillments + this._createQueryParams(queryParams);
    return this._api
      .get(url)
      .then((res) => new GlobalTransitFulfillmentResponseDTO(res));
  }
}

export default new FulfillmentRepository({
  api: api,
  apiGetFullfilments: API_GET_FULFILLMENT,
  apiGlobalFulfillments: API_GLOBAL_FULFILLMENT
});

export { FulfillmentRepository };
